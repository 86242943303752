/*animated scroll arrow animation*/
@keyframes fade_move_down {
    0% {
        transform: translate(0, -250%);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translate(0, -50%);
        opacity: 0;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-359deg);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
    }
    10% {
        transform: scale(0.9);
        animation-timing-function: ease-in;
    }
    17% {
        transform: scale(1.15);
        animation-timing-function: ease-out;
    }
    33% {
        transform: scale(0.95);
        animation-timing-function: ease-in;
    }
    45% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
}

@keyframes loaderOpacity {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loaderBounceN {
    0% {
        transform: translate(0,0);
    }
    50% {
        transform: translate(0,-15px);
    }
    100% {
        transform: translate(0,0);
    }
}

@keyframes loaderBounceSE {
    0% {
        transform: translate(0,0);
    }
    50% {
        transform: translate(15px,15px);
    }
    100% {
        transform: translate(0,0);
    }
}

@keyframes loaderBounceSW {
    0% {
        transform: translate(0,0);
    }
    50% {
        transform: translate(-15px,15px);
    }
    100% {
        transform: translate(0,0);
    }
}

@keyframes loaderRotate {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(-10deg);
    }
}

@keyframes loaderFullRotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@keyframes blink-2 {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translateX(0) rotate(0);
    }
    100% {
        transform: translateX(100vw) rotate(1080deg);
    }
}