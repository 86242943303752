footer {
    background-color: $bg-footer;
    color: $c-text;

    a:not(.button) {
        color: $c-text;

        .no-touchevents &:hover,
        .no-touchevents &:focus,
        .no-touchevents &:focus-within {
            color: $c-primary;
        }
    }

    nav.menu ul {
        margin: 0;
        padding: 0;
        list-style: none;

        &.social-menu{
            a:not(.button) {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                > i {
                    margin-right: 12px;
                    font-size: 16px;
                }
            }
        }

        li {

            & > a:not(.button),
            & > span:not(.button) {
                display: block;
            }

            & > a:not(.button) {
                font-size: 14px;
                text-decoration: none;
            }
        }
    }

    .footer-cols {
        padding-top: 40px;
        margin-bottom: 40px;

        @media (min-width: $s-tablet) {
            padding-top: 50px;
            margin-bottom: 20px;
        }

        nav.menu {

            & > ul {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 30px 40px;

                @media (min-width: $s-mobile) {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 50px 20vw
                }

                @media (min-width: $s-tablet) {
                    grid-template-columns: repeat(4, 1fr);
                    gap: 40px 75px;
                }

                & > li {
                    display: block;
                    max-width: 100%;

                    &.col {
                        text-align: left;
                    }

                    &.col1{
                        margin-bottom: 20px;

                        @media (min-width: $s-mobile) {
                            margin-bottom: 0px;
                        }

                        .infos{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            > div{

                                &:not(:last-child) {
                                    margin-bottom: 8px;
                                }

                                > a,
                                > span {
                                    font-weight: normal;
                                    text-decoration: none;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    > i {
                                        font-size: 16px;
                                        margin-right: 12px;
                                    }
                                }
                            }
                        }

                        .buttons{
                            margin-top: 20px;
                            display: flex;
                            flex-direction: column;

                            .button{
                                width: 100%;

                                &:not(:last-child) {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }

                    &.col2,
                    &.col3,
                    &.col4, {
                        .title{
                            margin-bottom: 0;

                            @media (min-width: ($s-mobile)) {
                                margin-bottom: 18px;
                            }
                        }
                        ul {
                            > li {
                                &:not(:last-child) {
                                    margin-bottom: 18px;

                                    @media (min-width: $s-mobile) {
                                        margin-bottom: 8px;
                                    }
                                }
                            }
                        }

                        .collapsible-content{
                            padding-top: 20px;
                            padding-left: 6px;

                            @media (min-width: ($s-mobile)) {
                                padding-top: 0;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }

        .logo {

            a {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                height: 20px;
                margin-bottom: 24px;

                img {
                    display: block;
                    //max-width: 100%;
                    //max-height: 100%;
                    height: 100%;

                    //&.for-light-bg {
                    //    display: none;
                    //}
                }
            }
        }
    }

    .footer-bottom {

        nav.menu {

            ul {
                display: flex;
                align-items: center;
                justify-content: center;

                & > li {
                    padding: 5px 10px;
                }
            }
        }

        .bottom {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            opacity: 0.3;
            padding-bottom: 30px;

            p {
                font-size: 14px;

                &:not(:last-child) {
                    &::after {
                        content: '-';
                        margin: 0 5px;
                    }
                }
            }

            a{
                font-weight: normal;
                text-decoration: none;

                .no-touchevents &:hover {
                    color: $c-text;
                    text-decoration: underline;
                }
            }
        }
    }
}
