@use "sass:math";

//@import "var";

@import "normalize";
@import "mixins";

@import "rgpd";
@import "header";
@import "footer";
@import "content-block";
@import "button";
@import "animation";
@import "blog";
@import "pagination";
@import "sitemap";
@import "errors";
@import "fonts";
@import "forms";
@import "map";
@import "video";
@import "popup";
@import "social_wall";
@import "select2";
@import "dropzone";

@import '~lightbox2/dist/css/lightbox';
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

@import "lightbox";

@import "hamburger";
@import "locale_switcher";
@import "breadcrumb";
//@import "jquery-ui";
//@import "dropdown";


#preprod-advert {
    position: fixed;
    z-index: 101;
    bottom: 0;
    width: 100%;
    padding: 15px;
    font: {
        family: $f-primary;
        size: 30px;
        weight: 700;
    }
    line-height: 1;
    color: white;
    text-align: center;
    text-transform: uppercase;
    background-color: transparentize(darkred, 0.3);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.2s;

    animation: blink-2 3s infinite both;

    & > i {
        position: absolute;
        bottom: 100%;
        left: 0;
        //width: 40px;
        //height: 30px;
        color: transparentize(darkred, 0.3);
        //color: white;

        animation: slide-in-left 5s linear infinite both;
    }
}


* {
    box-sizing: border-box;
}


// region [TITLES AND PARAGRAPHS]
::selection {
    background: $c-primary;
    color: $c-white;

    .bg-dark & {
        background: $c-light;
        color: $c-primary;
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font: {
        family: $f-primary;
        weight: 700;
    }
    margin: 0 0 20px 0;
    color: $c-text;
    line-height: 1.25;
}

h1, .h1 {
    font-size: 25px;
    word-break: break-word;

    @media (min-width: $s-mobile) {
        font-size: 36px;
    }
}

h2, .h2 {
    font-size: 20px;
    word-break: break-word;

    @media (min-width: $s-mobile) {
        font-size: 25px;
    }
}

h3, .h3 {
    font-size: 16px;

    @media (min-width: $s-mobile) {
        font-size: 20px;
    }
}

h4, .h4 {
    font-size: 16px;
}

h5, .h5 {
    font-size: 14px;
}

h6, .h6 {
    font-size: 14px;
}

p {
    margin: 0 0 15px 0;

    &:last-child {
        margin-bottom: 0;
    }
}

.wysiwyg-content {
}

// endregion

.site-content {
    max-width: $site-width;
    margin: 0 auto;
    padding: {
        left: $site-width-pad-x;
        right: $site-width-pad-x;
    }

    @media (min-width: $site-width-no-padding) {
        padding: {
            left: 0;
            right: 0;
        }
    }

    &.no-padding{
        padding: {
            left: 0;
            right: 0;
        }
    }
}

.site-content-full {
    @extend .site-content;
    max-width: none;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: ($site-width + (2 * $header-height-max))) {
        padding-left: $header-height-max;
        padding-right: $header-height-max;
    }
}


.site-content-l {
    @extend .site-content;
    max-width: $s-desktop-l;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: ($s-desktop-l + 60px)) {
        padding: 0;
    }
}
.site-content-xl {
    @extend .site-content;
    max-width: $s-desktop-xl;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: ($s-desktop-xl + 60px)) {
        padding: 0;
    }
}
.site-content-md {
    @extend .site-content;
    max-width: $s-tablet;

    @media (min-width: ($s-tablet + 60px)) {
        padding: {
            left: 0;
            right: 0;
        }
    }
}
.site-content-sm {
    @extend .site-content;
    max-width: 800px;

    @media (min-width: 840px) {
        padding: {
            left: 0;
            right: 0;
        }
    }
}
.site-content-xs {
    @extend .site-content;
    max-width: $s-mobile-md;

    @media (min-width: $s-mobile-md + 20px) {
        padding: {
            left: 0;
            right: 0;
        }
    }
}


.picture img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
}



@mixin overlay($top: 0, $left: 0, $width: 100%, $height: 100%) {
    &,
    &::before,
    &::after {
        position: absolute;
        top: $top;
        left: $left;
        width: $width;
        height: $height;
        pointer-events: none;
    }

    &::before,
    &::after {
        content: '';
    }
}

.overlay {
    @include overlay();
}


.hidden {
    display: none !important;
    //visibility: hidden !important;
    //display: none;
}
.padding-0 {
    padding: 0 !important;
}
.padding-top-0 {
    padding-top: 0 !important;
}
.padding-bottom-0 {
    padding-bottom: 0 !important;
}
.margin-0 {
    margin: 0 !important;
}
.margin-top-0 {
    margin-top: 0 !important;
}
.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.wysiwyg-content {
    a {
        color: $c-text;
        text-decoration: underline;
        font-weight: bold;

        .no-touchevents &:hover {
            color: $c-primary;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }

    span.text-primary {
        color: $c-primary;
    }
    span.text-secondary {
        color: $c-second;
    }
}

.share-links {
    //font-size: 12px;
    //color: $c-text;
    //
    //display: flex;
    //flex-wrap: wrap;
    //align-items: center;
    //
    //p, ul {
    //    display: inline-block;
    //    vertical-align: middle;
    //}
    //
    //.share-short.hidden {
    //    display: none;
    //}
    //
    //ul.share-links-list {
    //    margin: 0;
    //    padding: 0;
    //    list-style: none;
    //
    //    li {
    //        display: inline-block;
    //        vertical-align: middle;
    //        margin: 0;
    //        padding: 0;
    //
    //        a {
    //            display: block;
    //            padding: 2px 3px;
    //            font-size: 20px;
    //            color: $c-text;
    //
    //            .no-touchevents &:hover {
    //                color: $c-primary;
    //            }
    //        }
    //    }
    //}

    //.navigator-share {
    //    display: none;
    //    color: $c-text;
    //    line-height: 1;
    //
    //    &.visible {
    //        height: 30px;
    //        display: flex;
    //        text-decoration: none;
    //        align-items: center;
    //    }
    //
    //    i {
    //        margin-left: 5px;
    //        font-size: 20px;
    //    }
    //
    //    .no-touchevents &:hover {
    //        color: $c-primary;
    //    }
    //}
}

.slider-controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-content {
    position: relative;

    @media (min-width: $s-absolute-slider-btn) and (max-width: $s-absolute-slider-btn + 140px) {
        padding: {
            left: 80px;
            right: 80px;
        }
    }

    .slider-controls {

        @media (min-width: $s-absolute-slider-btn) and (max-width: $s-absolute-slider-btn + 140px) {

            button.slick-arrow {
                &.slick-prev {
                    left: 15px;
                }
                &.slick-next {
                    right: 15px;
                }
            }
        }
    }
}

.prev-next {
    padding: 35px 0;
    text-align: center;

    @media (min-width: $s-tablet) {
        padding: 70px 0;
    }

    .prev-next-list {
        position: relative;
        display: flex;
        flex-direction: column-reverse;

        @media (min-width: $s-mobile) {
            justify-content: center;
            flex-direction: row;
        }

        .item-prev-next {
            position: relative;
            width: 100%;
            max-width: $s-mobile-md;
            margin: 0 auto;
            padding: 0;
            transform: translateX(0);
            transition: transform $t-duration;

            @media (min-width: $s-mobile) {
                display: flex;
                flex-direction: column;
                width: 50%;
            }

            @media (min-width: $s-tablet) {
                justify-content: space-between;
            }

            &:not(:only-child):not(:last-child) {

                &::after {
                    content: '';
                    position: absolute;
                    top: -21px;
                    left: calc(50% - 75px);
                    width: 150px;
                    height: 2px;
                    background-color: #262626;

                    @media (min-width: $s-mobile) {
                        top: calc(50% - 30px);
                        left: calc(100% - 1px);
                        height: 60px;
                        width: 2px;
                    }
                }
            }

            &.prev {
                margin-top: 20px;
                text-align: left;

                @media (min-width: $s-mobile) {
                    align-items: flex-end;
                    margin-top: 0;
                    padding-right: 50px;
                    text-align: right;
                }

                @media (min-width: $s-desktop-l) {
                    flex-direction: row-reverse;
                    align-items: center;
                }

                &:not(:only-child):not(:last-child) {

                    @media (min-width: $s-mobile) {
                        margin-right: 0;
                    }
                }

                .content {
                    align-items: flex-start;

                    @media (min-width: $s-mobile) {
                        align-items: flex-end;
                    }

                    @media (min-width: $s-tablet) {
                        flex-direction: row;
                        align-items: center;
                    }

                    & > i {

                        @media (min-width: $s-tablet) {
                            margin-right: 20px;
                        }
                    }
                }
            }

            &.next {
                margin-bottom: 10px;
                text-align: right;

                @media (min-width: $s-mobile) {
                    margin-bottom: 0;
                    margin-left: 0;
                    padding-left: 50px;
                    text-align: left;
                }

                @media (min-width: $s-desktop-l) {
                    flex-direction: row;
                    align-items: center;
                }

                .content {
                    align-items: flex-end;

                    @media (min-width: $s-mobile) {
                        align-items: flex-start;
                    }

                    @media (min-width: $s-tablet) {
                        flex-direction: row-reverse;
                        align-items: center;
                    }

                    & > i {

                        @media (min-width: $s-tablet) {
                            margin-left: 20px;
                        }
                    }
                }
            }

            .thumbnail {
                display: none;

                @media (min-width: $s-desktop-l) {
                    display: block;
                    width: 150px;
                    height: 100px;
                    background: {
                        position: center;
                        repeat: no-repeat;
                        size: cover;
                    }
                    opacity: 1;
                    transition: opacity $t-duration;

                    .no-touchevents & {
                        opacity: 0.2;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: column-reverse;

                @media (min-width: $s-mobile) {
                    justify-content: space-between;
                    width: 100%;
                }

                @media (min-width: $s-desktop-l) {
                    width: calc(100% - 160px);
                }

                & > i {
                    margin-top: 10px;
                    font-size: 20px;

                    @media (min-width: $s-tablet) {
                        margin-top: 0;
                    }
                }
            }

            h3, .h3 {
                display: block;
                margin-bottom: 0;

                & > span {
                    display: block;
                }
            }

            .no-touchevents &:hover {

                &.prev .content > i {
                    transform: translateX(-5px);
                }
                &.next .content > i {
                    transform: translateX(5px);
                }

                .thumbnail {
                    opacity: 1;
                }

                .content > i {
                    color: $c-primary;
                }
            }
        }
    }
}

details.expandable-text {
    position: relative;

    &[open] {
        padding-bottom: 30px;

        summary {

            .summary {
                display: none;
            }

            i.icon-arrow-angle-down {
                width: max-content;

                &.top-icon {
                    display: block;
                }
                &.bottom-icon {
                    display: none;
                }
            }
        }
    }

    summary {
        touch-action: manipulation;
        list-style: none;

        &::-webkit-details-marker {
            display: none;
        }

        &:focus {
            outline: none;
        }

        i.icon-arrow-angle-down {
            cursor: pointer;
            font-size: 10px;

            &.top-icon {
                display: none;
                //margin-bottom: 15px;
                position: absolute;
                bottom: 0;
            }
            &.bottom-icon {
                display: block;
                margin-top: 15px;
            }
        }
    }
}


// region [Overwrite _header.scss]
header {

    &::after {
        display: none;
    }
}
// endregion


// region [Overwrite _footer.scss]
footer {
}
// endregion


// region [Overwrite _locale_switcher.scss]
.nav-locale-switcher {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    body.scrolling-up header:not(.expanded):not(.nav-deployed) & {
        color: $c-text;

        .locale-switcher__button,
        .locale-switcher__label,
        .locale-switcher__list li > a {
            color: $c-text;

            .no-touchevents &:hover,
            .no-touchevents &:focus,
            .no-touchevents &:focus-within {
                color: $c-second;
            }
        }
    }

    .locale-switcher__button {

        .no-touchevents &:hover,
        .no-touchevents &:focus,
        .no-touchevents &:focus-within {
            color: $c-second;
        }

        &[aria-expanded="true"] {
            .locale-switcher__label {
                color: $c-second;
            }
        }
    }

    .locale-switcher__label {
        color: $c-text-light;

        .no-touchevents &:hover,
        .no-touchevents &:focus,
        .no-touchevents &:focus-within {
            color: $c-second;
        }

        body.page-light &,
        header.nav-deployed &,
        header.expanded & {
            color: $c-text-dark;

            .no-touchevents &:hover,
            .no-touchevents &:focus,
            .no-touchevents &:focus-within {
                color: $c-second;
            }
        }

        i {
            color: $c-second;
        }
    }

    .locale-switcher__list {

        li > a {
            color: $c-text-light;

            .no-touchevents &:hover,
            .no-touchevents &:focus,
            .no-touchevents &:focus-within {
                color: $c-second;
            }

            body.page-light &,
            header.nav-deployed & {
                color: $c-text-dark;

                .no-touchevents &:hover,
                .no-touchevents &:focus,
                .no-touchevents &:focus-within {
                    color: $c-second;
                }
            }
        }
    }
}
// endregion


// region [Overwrite _video.scss]
a.button-video {}
// endregion


// region [Overwrite _popup.scss]
#whatever-the-popup {}
// endregion


// region [Overwrite _content-block.scss]
.content-block {}
// endregion


// region [Overwrite _blog.scss]
.blogs-list {}
// endregion


// region [Overwrite _social_wall.scss]
.social-wall {}
// endregion







// region [SPECIFIC BACKGROUNDS]
.bg-light,
.section.bg-light{
    background: $bg-light url('../images/pattern_light.jpg');
}

.bg-white,
.section.bg-white{
    background: $bg-white;
}

.bg-dark,
.section.bg-dark {
    background: $bg-dark url('../images/pattern_dark.jpg');
    color: $c-text-light;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        color: $c-text-light;
    }
}
// endregion


body {
    font: {
        family: $f-text;
        size: $f-size-base;
        weight: normal;
    }
    line-height: $f-line-height-base;
    color: $c-text;

    &.page-dark {
        background-color: $bg-dark;
    }

    &.page-light {
        background-color: $bg-light;
    }

    &.parallax-images-loaded main > section.banner.with-bg {
        background-color: transparent;
    }
}

main {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: $header-height-min;

    @media (min-width: $s-nav-burger-limit) {
        padding-top: $header-height-max;
    }

    //
    //&.with-top-banner {
    //
    //    @media (min-width: $s-nav-burger-limit) {
    //        padding-top: $header-height-max + $header-top-banner-height;
    //    }
    //}
}

section {
    position: relative;
    padding: $section-pad-y-min 0;

    @media (min-width: $s-mobile) {
        padding: $section-pad-y-max 0;
    }

    &:first-of-type {
        padding-top: 0;

        main &:not(.banner) {
            padding-top: $header-height-min;

            @media (min-width: $s-nav-burger-limit) {
                padding-top: $header-height-max;
            }
        }

        main.with-top-banner &:not(.banner) {
            padding-top: $header-height-min + $header-top-banner-height;

            @media (min-width: $s-nav-burger-limit) {
                padding-top: $header-height-max + $header-top-banner-height;
            }
        }
    }
    &:last-of-type:not(.bottom) {
        //padding-bottom: 0;
    }

    &.banner {
        padding-top: 0;
        padding-bottom: 0;

        &.with-bg {
            //min-height: 500px;
            //padding-bottom: 0;
            background-color: $c-second;
            transition: background-color 2*$t-duration;

            .background {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                pointer-events: none;

                .simpleParallax {
                    height: 100%;
                }

                .parallax-underlay {
                    width: 100%;
                }

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            .overlay {
                z-index: 0;
                height: 300px;
                max-height: 100%;
                background-image: linear-gradient(180deg, transparentize($c-dark, 0.15) 0%, transparentize($c-dark, 1) 100%);
            }

            .banner-content {
                z-index: 10;
                padding-bottom: $section-pad-y-min;

                @media (min-width: $s-mobile) {
                    padding-bottom: $section-pad-y-max;
                }

                @media (min-width: $s-tablet) {
                    padding-bottom: 2 * $section-pad-y-max;
                }

                h1, .h1,
                h2, .h2,
                .intro,
                .text,
                a.back {
                    color: $c-white;
                    text-shadow: 0 0 35px transparentize($c-dark, 0.3);
                }



                .picture{
                    filter: invert(1);
                }
            }

            & + section {
                margin-top: 0;
                //padding-top: 0;
                //padding-top: 50px;

                &:not(.bg-dark) {
                    background-color: $bg-white;
                }

                //@media (min-width: $s-desktop) {
                //
                //    &:not(.intro) {
                //        padding-top: 85px;
                //    }
                //}
            }
        }

        .banner-content {
            position: relative;
            display: flex;
            flex-direction: column-reverse;

            @media (min-width: $s-mobile) {
                flex-direction: row;
                align-items: stretch;
            }

            & > .content {

                @media (min-width: $s-mobile) {
                    flex: 0 1 60%;
                }

                .title {

                    br {
                        display: none;
                    }

                    @media (min-width: $s-mobile) {

                        br {
                            display: initial;
                        }
                    }
                }
            }

            & > .picture {
                margin-bottom: 10px;

                @media (min-width: $s-mobile) {
                    flex: 1 1 auto;
                    margin-top: -10px;
                    margin-bottom: -10px;
                }

                img {
                    display: flex;
                    max-height: 90px;

                    @media (min-width: $s-mobile) {
                        max-height: 185px;
                        margin-left: auto;
                        margin-right: 0;
                    }
                }
            }
        }

    }

    &.sections {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.bottom {
        order: 1000;
        padding: 50px 0;
        text-align: center;

        details.expandable-text {
            summary i.icon-arrow-angle-down.top-icon {
                left: calc(50% - (15px * 0.5));
            }
        }
    }
}



// region [HOMEPAGE SPECIFIC STYLES]
body.home {

    section.testimonies{

        .list{
            $gap: 30px;

            column-count: 1;
            column-gap: $gap;

            @media (min-width: ($s-mobile)) {
                column-count: 2;
            }

            @media (min-width: ($s-tablet)) {
                column-count: 3;
            }


            .testimony{
                break-inside: avoid;
                page-break-inside: avoid;
                margin-bottom: $gap * 0.67;

                .picture{
                    height: 177px;
                    padding: 30px;

                    img{
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                }

                .infos{
                    padding: 40px;

                    @media (min-width: ($s-tablet)) {
                        padding: 50px;
                    }

                    .text{
                        margin-bottom: 20px;

                        &:before {
                            content: "“";
                        }

                        &:after {
                            content: "”";
                        }
                    }

                    .name{
                        margin-bottom: 0;
                    }

                    .job{
                        margin-top: 3px;
                        font-weight: initial;
                        font-size: initial;
                    }
                }

            }
        }
    }

}
// endregion


.lazyload, .lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

img.lazyload:not([src]) {
    visibility: hidden;
}



// region [COMMONS]
.content-limited{
    max-width: $site-width-limited;
    margin-left: auto;
    margin-right: auto;
}

.limited{
    text-align: center;

    @media (min-width: ($s-mobile)) {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}

mark {
    $color1: $c-second;
    $color2: $c-fourth;
    $color3: rgba(246, 246, 246, 0.3); // For dark sections
    $gradientStart : 20%;
    $gradientEnd : 80%;

    font-family: $f-highlight;
    font-weight: normal;
    font-size: 1.4em; // The highlighted words are bigger than the regular font
    line-height: 1;
    z-index: -1;

    &.highlight-1 {
        background: $color1;
        background: linear-gradient(transparent $gradientStart, $color1 $gradientStart, $color1 $gradientEnd, transparent $gradientEnd);
    }
    &.highlight-2 {
        background: $color2;
        background: linear-gradient(transparent $gradientStart, $color2 $gradientStart, $color2 $gradientEnd, transparent $gradientEnd);
    }

    .bg-dark &,
    .with-bg &,
    .section.bg-dark & {
        &.highlight-1,
        &.highlight-2 {
            color: $c-text-light;
            background: linear-gradient(transparent $gradientStart, $color3 $gradientStart, $color3 $gradientEnd, transparent $gradientEnd);
        }
    }
}

.collapsible-wrapper{

    .collapsible-handle{
        display: flex;
        align-items: center;
        cursor: pointer;

        > span{
            margin-right: 8px;
        }

        > i{
            margin-left: auto;
            transition: transform $t-duration ease;
            transform: rotate(0deg);
        }
    }

    .collapsible-content{
    }

    &.collapsible-closed{

        .collapsible-handle{
            > i{
                transform: rotate(180deg);
            }
        }

        .collapsible-content{
        }
    }

    // Desactivate collapsible when not on mobile
    &.collapsible-mobile-only{
        .collapsible-handle{
            @media (min-width: $s-mobile) {
                cursor: default;
            }

            > i{

                @media (min-width: $s-mobile) {
                    display: none;
                }
            }
        }

        .collapsible-content{
            @media (min-width: $s-mobile) {
                display: block !important;
            }
        }
    }
}

.tags {
    $gap: 10px;

    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .tag {
        font-weight: bold;
        color: $c-text;
        font-size: 12px;
        padding: 9px 14px;
        border-radius: 100px;
        background-color: $c-second;
        line-height: 1;
        margin-bottom: $gap;

        &:not(:last-child) {
            margin-right: $gap;
        }

        &.big {
            font-size: 14px;
            padding: 10px 17px;
        }
    }
}

@mixin scroll-indicator($size, $offset) {
    position: relative;
    bottom: - $size * $offset;

    .scroll-down {
        position: absolute;
        bottom: 20px;
        width: 100%;
        height: 100% - (100% * $offset);
        inset: 0;

        a {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 100%;
            width: 100%;
            text-decoration: none;

            > i {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
            }
        }

        &.animated {
            a > i {
                animation: 2s ease-out infinite fade_move_down;
            }

        }
    }

    .picture-circle {
        display: none;
        position: relative;
        justify-content: center;
        align-items: center;
        width: $size;
        height: $size;
        margin: 0 auto;
        animation: rotation 30s infinite linear;

        img {
            width: 100%;
        }

        @media (min-width: ($s-mobile-md)) {
            display: flex;
        }
    }
}

// endregion