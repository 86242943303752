$form-field-text-font-f: 'Arial, sans-serif' !default;

// Default variables
$font_size_small: 12px !default;

$input_height: 50px !default;
$input_height_small: 36px !default;
$dropdown_padding: 15px !default;

$gray: #999 !default;
$gray_light: #f8f8f8 !default;
$black: #000000 !default;
$primary_light: $gray !default;
$arrow_color: $black !default;

select.nice-select {
    display: block !important;
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    min-height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    min-width: 1px !important;
}

// Style the dropdown
.nice-select, .nice-select.form-control {
    position: relative;
    padding: {
        //top: 6.5px;
        right: 35px;
        //bottom: 6.5px;
    }
    cursor: pointer;

    @media (min-width: $s-mobile) {
        //padding: {
        //    top: 16.5px;
        //    bottom: 16.5px;
        //}
    }

    & .form-control {
        width:auto;
        border-color:$c-text;
        font-size:15px;
    }

    &:hover {
        border-color: transparentize($c-text, 0.9);

        &::after {
            color: $c-primary;
        }
    }

    &:active, &:focus {
        border-color: transparentize($c-text, 0.9);
    }

    // Arrow
    &::after {
        content:'\0043';
        display: inline-block;
        font-family: $f-icons;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: color $t-duration, transform $t-duration;
        position: absolute;
        bottom: calc(50% - 4px);
        right: 15px;
        width: 10px;
        font-size: 10px;
        text-align: center;
    }

    &.open {
        &::after {
            transform: rotate(-180deg);
        }
        .list {
            z-index: 99;
            transform: translateY(0);
            opacity: 1;
            pointer-events: auto;
        }
    }
    &.disabled {
        border-color: lighten($gray_light, 2%);
        color: $gray;
        pointer-events: none;
        &::after {
            border-color: lighten($arrow_color, 20%);
        }
    }

    // Modifiers
    &.wide {
        width: 100%;
        .list {
            left: 0 !important;
            right: 0 !important;
        }
    }
    &.right {
        float: right;
        .list {
            left: auto;
            right: 0;
        }
    }
    &.small {
        font-size: $font_size_small;
        height: $input_height_small;
        line-height: $input_height_small - 2;
        &:after {
            height: 4px;
            width: 4px;
        }
        .option {
            line-height: $input_height_small - 2;
            min-height: $input_height_small - 2;
        }
    }

    .current {
        padding-right: 30px;
        line-height: $form-field-pad-h * 2;

        @media (min-width: $s-mobile) {
            line-height: 30px;
        }
    }

    // List and options
    .list {
        position: absolute;
        z-index: 9;
        top: calc(100% + 10px);
        left: -1px;
        right: -1px;
        //min-width: 100%;
        //width: max-content;
        max-height: 200px;
        margin-top: 0;
        padding: 0;
        transform: translateY(-5px);

        background-color: $form-field-bg-color;
        border-radius: 5px;
        box-shadow: 0 10px 15px 0 transparentize($c-primary, 0.85);
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        pointer-events: none;
        opacity: 0;

        font-family: $form-field-text-font-f;
        font-size: 15px;

        transition: opacity $t-duration, transform 2*$t-duration;
    }

    .option {
        cursor: pointer;
        font-weight: 400;
        line-height: $input_height - 10px;
        list-style: none;
        //width: 100%;
        min-height: $input_height - 10px;
        padding-left: $dropdown_padding;
        padding-right: $dropdown_padding + 11;
        text-align: left;
        transition: all 0.2s;
        &:hover, &.focus {
            background-color: transparentize($c-primary, 0.3);
            color: $form-field-text-color;
        }
        &.selected, &.selected.focus {
            background-color: $c-primary;
            color: $c-text-light;
        }
        &.disabled {
            background-color: transparent;
            color: $gray;
            cursor: default;
        }
    }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
    .list {
        display: none;
    }
    &.open {
        .list {
            display: block;
        }
    }
}






.bg-dark,
section.bg-dark {

    .nice-select, .nice-select.form-control {

        .list {
            background-color: $form-field-bg-color-bis;
        }
    }
}