body.page.sitemap {

    main {

        ul {
            list-style: none;
            margin: 0;
            padding-left: 10px;

            @media (min-width: $s-mobile-md) {
                padding-left: 25px;
            }

            &.main-level {

                @media (min-width: $s-mobile-md) {
                    columns: 2;
                }

                & > li {
                    break-inside: avoid;
                }
            }
        }

        h2, .h2,
        h3, .h3,
        h4, .h4 {
            margin: 0 0 5px 0;
            font: {
                family: $f-text;
                size: 15px;
                weight: normal;
            }
            line-height: 25px;

            & > a {
                display: flex;

                & > i {
                    font-size: 13px;
                }
            }
        }
    }

}