@import "pure-icons";
@import "font-awesome/font-awesome";

@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');

@font-face {
    font-family: 'Manier';
    src:    url('../fonts/manier-medium.eot');
    src:    url('../fonts/manier-medium.eot?#iefix') format('embedded-opentype'),
            url('../fonts/manier-medium.woff2') format('woff2'),
            url('../fonts/manier-medium.woff') format('woff'),
            url('../fonts/manier-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Manier';
    src:    url('../fonts/manier-heavy.eot');
    src:    url('../fonts/manier-heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/manier-heavy.woff2') format('woff2'),
    url('../fonts/manier-heavy.woff') format('woff'),
    url('../fonts/manier-heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'Manier';
    src:    url('../fonts/manier-light.eot');
    src:    url('../fonts/manier-light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/manier-light.woff2') format('woff2'),
    url('../fonts/manier-light.woff') format('woff'),
    url('../fonts/manier-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
