header {
    position: fixed;
    top: 0;
    z-index: 199;
    width: 100%;
    height: $header-height-min;
    background-color: $bg-white;
    transition: {
        property: box-shadow, transform, background-color;
        duration: $t-duration;
    }
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

    @media (min-width: $s-nav-burger-limit) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: $header-height-max;
    }

    &::before,
    &::after {
        content: '';
        position: fixed;
        z-index: 152;
        top: 0;
        left: 0;
        width: 100%;
        height: $header-height-min;
        background-color: $bg-white;
        pointer-events: none;

        @media (min-width: $s-nav-burger-limit) {
            height: $header-height-max;
        }
    }

    &::before {
        //box-shadow: 0 3px 35px 0 rgba(0, 0, 0, 0.1);
        opacity: 0;
        transition: opacity $t-duration;

        @media (min-width: $s-nav-burger-limit) {
            display: none;
        }
    }

    &::after {
        z-index: 150;
    }

    //&.light {
    //    background-color: $bg-white;
    //    box-shadow: 0 -5px 20px 0 transparentize($c-primary, 0.85);
    //}

    //body.scrolling-down &:not(.expanded):not(.nav-deployed) {
    //    transform: translateY(-100%);
    //}

    //body.scrolling-up &:not(.expanded):not(.nav-deployed) {
    //    transform: translateY(0);
    //    background-color: $bg-light;
    //    box-shadow: 0 -5px 20px 0 transparentize($c-primary, 0.85);
    //}

    body.scrolling & {
        box-shadow: 0 3px 35px 0 rgba(0, 0, 0, 0.05);
    }

    &.nav-deployed {
        &::before {
            opacity: 1;
        }
    }

    &.expanded {

        @media (min-width: $s-nav-burger-limit) {
            background-color: $bg-white;
        }
    }

    &.with-top-banner {

        @media (min-width: $s-nav-burger-limit) {
            margin-top: $header-top-banner-height;
            transition-property: box-shadow, transform, background-color, margin-top;

            body.scrolling & {
                margin-top: 0;
            }
        }

        .nav-content .mega-nav {

            @media (min-width: $s-nav-burger-limit) {
                top: $header-height-max + $header-top-banner-height;
            }
        }
    }

    .header-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .logo {
        display: flex;
        align-items: center;
        //position: absolute;
        //top: ($header-height-min - $logo-height-min) * 0.5;
        //left: ($header-height-min - $logo-height-min) / 2;
        //left: 10px;
        width: 100%;
        z-index: 153;
        max-width: $logo-width-min;
        padding: 0;
        transition: transform $t-duration, margin-right $t-duration;

        @media (min-width: $s-nav-burger-limit) {
            margin-right: 16px;
            //top: ($header-height-max - $logo-height-max) * 0.5;
            //left: ($header-height-max - $logo-height-max) / 2;
            max-width: $logo-width-max;
        }
        @media (min-width: $site-width) {
            margin-right: 32px;
        }

        a {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: $logo-height-min;
            padding: 0;

            @media (min-width: $s-nav-burger-limit) {
                height: $logo-height-max;
            }

            img {
                //position: absolute;
                //top: 0;
                //left: 0;
                //max-width: 100%;
                //max-height: 100%;
                //opacity: 0;
                //transition: all $t-duration;

                position: relative;
                width: 100%;
                height: 100%;
                object-fit: contain;

                @media (min-width: $s-nav-burger-limit) {
                    top: -2px;
                }
            }
        }
    }

    .nav-content {
        position: fixed;
        z-index: 151;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: $bg-white;
        transition: {
            property: opacity, background-color;
            duration: $t-duration;
        }
        pointer-events: none;

        @media (min-width: $s-nav-burger-limit) {
            //display: inline-block;
            //vertical-align: top;
            position: relative;
            //left: inherit;
            //bottom: inherit;
            //width: 100%;
            background-color: transparent;
            opacity: 1;
            pointer-events: auto;
        }

        &.deploy {
            opacity: 1;
            pointer-events: auto;
            transition: opacity $t-duration;
        }

        .wrapper {
            position: relative;
            overflow-x: hidden;
            overflow-y: scroll;
            width: 100%;
            height: $wrapper-height-min;
            margin-top: $header-height-min;

            @media (min-width: $s-nav-burger-limit) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: $wrapper-height-max;
                margin-top: 0;
                overflow: initial;
            }

            & > nav.menu ul,
            & > .mega-nav nav.menu ul {
                position: relative;
                transition: transform $t-duration;

                &.main-menu,
                &.header_menu_primary-menu {
                    font-size: 20px;

                    @media(min-width: $s-nav-burger-limit) {
                        display: flex;
                        justify-content: center;
                        font-size: 14px;
                    }

                    & > li {

                        @media (min-width: $s-nav-burger-limit) {
                            //display: inline-block;
                            //background: transparent;

                            //& > a:not(.button),
                            //& > span:not(.button) {
                            //    font-family: $f-primary;
                            //    color: $c-dark;
                            //    //min-height: $header-height-max - 15px;
                            //    //line-height: ($header-height-max - 30px) * 0.5;
                            //    //font-size: 15px;
                            //    transition: color $t-duration ease;
                            //    background-color: transparent;
                            //    border-bottom: none;
                            //
                            //    &::before {
                            //        display: block;
                            //    }
                            //}
                        }
                    }
                }

                &.hide {
                    transform: translateX(-100vw);

                    @media (min-width: $s-nav-burger-limit) {
                        transform: none;
                    }
                }

                & > li {

                    @media (min-width: $s-nav-burger-limit) {
                        display: flex;
                        align-items: center;
                        width: inherit;
                        height: inherit;
                        margin: 0;
                        //background: $c-white;
                    }

                    &:hover,
                    &.current,
                    &.expanded {

                        & > a:not(.button),
                        & > span:not(.button) {
                            color: $c-primary;
                        }

                        @media (min-width: $s-nav-burger-limit) {

                            & > a:not(.button),
                            & > span:not(.button) {
                                text-decoration: none;

                                .no-touchevents &::before {
                                    width: calc(100% - 40px);
                                }
                            }
                        }
                    }

                    &.back {

                        @media (min-width: $s-nav-burger-limit) {
                            display: none;
                        }
                    }

                    & > a:not(.button),
                    & > span:not(.button) {
                        position: relative;
                        cursor: pointer;
                        display: block;
                        padding: 20px $site-width-pad-x;
                        color: $c-text;
                        text-decoration: none;
                        //background-color: $c-white;
                        //border-bottom: 1px solid $c-border;
                        transition: {
                            property: color, background-color, padding-left, padding-right;
                            duration: $t-duration;
                        }

                        @media (min-width: $s-nav-burger-limit) {
                            border: none;
                            padding: 8px 12px;
                        }
                        @media (min-width: $site-width) {
                            padding: 8px 18px;
                        }


                        //.no-touchevents &::before {
                        //    display: block;
                        //    content: '';
                        //    position: absolute;
                        //    bottom: 0;
                        //    left: 50%;
                        //    width: 0;
                        //    height: 2px;
                        //    margin: 0;
                        //    background: $c-primary;
                        //    transform: translateX(-50%);
                        //    transition: width $t-duration*0.5, bottom $t-duration;
                        //}

                        //&.back {
                        //    padding-left: 30px;
                        //    font-weight: 400;
                        //    color: $c-primary;
                        //
                        //    &::after {
                        //        content: '\004b';
                        //        right: auto;
                        //        left: 0;
                        //    }
                        //}
                    }

                    &.mega > span,
                    &.back > span {
                        position: relative;
                        cursor: pointer;
                        //color: $c-primary;

                        &::after {
                            font-family: $f-icons;
                            display: inline-block;
                            font-style: normal;
                            font-weight: normal;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            content: '\4A'; // Warning: check pure icons code
                            position: absolute;
                            top: calc(50% - 7.5px);
                            right: 20px;
                            font-size: 15px;
                            //color: $c-primary;

                            @media (min-width: $s-nav-burger-limit) {
                                display: none;
                                content: '';
                            }
                        }

                        &.back {
                            padding-left: 50px;
                            font-weight: 400;
                            //color: $c-primary;

                            &::after {
                                content: '\004b';
                                right: auto;
                                left: 20px;
                            }
                        }
                    }
                }
            }

            .header-menu-secondary {
                padding-left: $site_width-pad-x;
                padding-right: $site_width-pad-x;

                @media(min-width: $s-nav-burger-limit) {
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                    padding-left: 0;
                    padding-right: 0;
                }

                .button {
                    width: 100%;
                    margin-top: 20px;

                    @media(min-width: $s-nav-burger-limit) {
                        width: auto;
                        margin-top: 0;
                    }

                    &.button1{
                        color:  $c-primary;

                        @media(min-width: $s-nav-burger-limit) {
                            background: transparent;
                            box-shadow: none;
                            padding: 8px 18px;
                            font-size: 14px;

                            .no-touchevents &:hover{
                                color: $c-text;
                                background: transparent;
                                box-shadow: none;
                            }
                        }

                        @media (min-width: $site-width) {
                            padding: 8px 28px;
                        }
                    }
                }
            }

            .additional-content {
                width: 100%;
                transition: transform $t-duration;

                @media (min-width: $s-nav-burger-limit) {
                    width: auto;
                }

                &.hide {
                    transform: translateX(-100vw);

                    @media (min-width: $s-nav-burger-limit) {
                        transform: none;
                    }
                }
            }

            nav.header-top {
                width: 100%;
                margin-top: 20px;
                background-color: $bg-footer;
                color: $c-text-light;

                @media (min-width: $s-nav-burger-limit) {
                    position: absolute;
                    top: -$header-top-banner-height;
                    left: 0;
                    height: $header-top-banner-height;
                    margin-top: 0;
                }

                .header-top-content {
                    display: flex;
                    flex-direction: column;

                    @media (min-width: $s-nav-burger-limit) {
                        flex-direction: row;
                    }
                }

                a,
                ul > li > a:not(.button) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 10px 20px;
                    color: $c-text-light;
                    text-decoration: none;
                    background-color: $bg-footer;

                    .no-touchevents &::before {
                        display: none;
                    }

                    @media (min-width: $s-nav-burger-limit) {
                        justify-content: center;
                        height: $header-top-banner-height;
                        padding: 0 15px;
                        //font: {
                        //    family: $f-text;
                        //    size: 12px;
                        //    weight: 700;
                        //}
                        line-height: $header-top-banner-height;
                        text-transform: none;
                    }

                    .no-touchevents &:hover,
                    .no-touchevents .block-link:hover & {
                        text-decoration: none;
                    }

                    & > i {
                        line-height: $header-top-banner-height;
                    }
                }

                .header-top-left,
                .header-top-middle,
                .header-top-right {

                    @media (min-width: $s-nav-burger-limit) {
                        padding: 0 5px;
                    }
                }

                .header-top-middle {
                    padding: 20px;

                    @media (min-width: $s-nav-burger-limit) {
                        flex-grow: 1;
                        padding: 0;
                        line-height: $header-top-banner-height;
                        text-align: center;

                        ul {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            li {
                                display: block;
                                height: $header-top-banner-height;
                            }
                        }
                    }
                }

                .header-top-right {
                    background-color: $c-primary;
                    color: $c-text;

                    a,
                    ul > li > a:not(.button) {
                        background-color: $c-primary;
                        color: $c-text;

                        .no-touchevents &:hover,
                        .no-touchevents .block-link:hover & {
                            color: $c-light;
                        }
                    }
                }
            }
        }

        .mega-nav {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 100%;
            width: 100%;
            background-color: $bg-white;
            transition: left $t-duration;

            @media (min-width: $s-nav-burger-limit) {
                position: fixed;
                top: $header-height-max;
                left: 0;
                border-top: 1px solid $c-border;
                opacity: 0;
                pointer-events: none;
                transition: all $t-duration;
            }

            &.visible,
            &.forced {
                left: 0;

                @media (min-width: $s-nav-burger-limit) {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
            }

            .mega-nav-content {
                padding: 20px 0;
                //padding: 0;
            }

            .title,
            .link {
                padding: 0 $site-width-pad-x;
            }

            nav.menu {

                @media (min-width: $s-nav-burger-limit) {
                    position: relative;
                    width: 100%;
                }

                &.back-menu {

                            @media (min-width: $s-nav-burger-limit) {
                                display: none;
                            }
                        }

                ul {

                    li {

                        a,
                        span {
                            &:not(.button) {

                                @media (min-width: $s-nav-burger-limit) {

                                    &::before,
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                & + .link {
                    margin-top: 20px;
                }
            }
        }
    }

    .nav-locale-switcher {
        position: absolute;
        z-index: 152;
        top: 0;
        right: $hamburger-total-width + 5px;
        width: 40px;
        height: 100%;

        @media (min-width: $s-nav-burger-limit) {
            right: 5px;
        }

        .locale-switcher {
            width: 100%;
            height: #{$f-size-base * $f-line-height-base};
            margin-top: 0;

            @media (min-width: $s-nav-burger-limit) {
                margin-top: 0;
            }
        }

        .locale-switcher__button {
            width: 100%;
            height: 100%;
            padding: 0;
            color: $c-white;
            text-align: left;
            text-transform: uppercase;
            background: none;
            border: none;
            cursor: pointer;
            transition: color $t-duration;

            .no-touchevents &:hover,
            .no-touchevents &:focus,
            .no-touchevents &:focus-within {

                .locale-switcher__label > i {
                    transform: translateY(2px);
                }

                &[aria-expanded="true"] {
                    .locale-switcher__label > i {
                        transform: rotate(180deg);
                    }
                }
            }

            &[aria-expanded="true"] {
                .locale-switcher__label > i {
                    transform: rotate(180deg);
                }
            }
        }

        .locale-switcher__label {
            display: block;
            transition: color $t-duration;

            i {
                margin-left: 10px;
                font-size: 10px;
                text-transform: none;
                transition: transform $t-duration;
                //
                //&::before {
                //    content: '\0043';
                //}
            }
        }

        .locale-switcher__list {
            margin: 0;
            padding: 0;
            list-style: none;
            min-width: 50px;
            font-size: $f-size-base;

            li > a {
                display: block;
                height: 20px;
                padding: 0;
                text-align: left;
                text-transform: uppercase;
                text-decoration: none;

                .no-touchevents &:hover,
                .no-touchevents &:focus,
                .no-touchevents &:focus-within {
                    text-decoration: none;
                    opacity: 1;
                }

                &[aria-selected="true"] {
                    display: none;
                }
            }
        }
    }

    .nav-button {
        position: relative;
        z-index: 152;
        //float: right;
        //margin-right: $hamburger-margin-right;

        @media (min-width: $s-nav-burger-limit) {
            display: none;
        }
    }






    body.page-light &,
    body.page-dark.scrolling-up &,
    body.page-dark &.expanded {

        .logo a img.for-light-bg {
            opacity: 1;
        }

        .logo a img.for-dark-bg {
            opacity: 0;
        }

        //.nav-content .wrapper {
        //
        //    & > nav.menu:not(.header-top) ul,
        //    & > .mega-nav nav.menu ul {
        //
        //        & > li > a:not(.button),
        //        & > li > span:not(.button),
        //        & > li > span:not(.button)::after {
        //            color: $c-primary;
        //        }
        //    }
        //}

        .hamburger-inner {

            &,
            &::before,
            &::after {
                background-color: $hamburger-active-layer-color;
            }
        }
    }

    body.page-dark &:not(.expanded) {

        &.nav-deployed {

            .logo a img.for-light-bg {
                opacity: 1;
            }

            .logo a img.for-dark-bg {
                opacity: 0;
            }

            .nav-content .wrapper {

                & > nav.menu:not(.header-top) ul,
                & > .mega-nav nav.menu ul {

                    & > li > a:not(.button),
                    & > li > span:not(.button),
                    & > li > span:not(.button)::after {
                        color: $c-primary;
                    }
                }
            }
        }

        .logo a img.for-light-bg {
            opacity: 0;
        }

        .logo a img.for-dark-bg {
            opacity: 1;
        }

        .nav-content .wrapper {

            & > nav.menu:not(.header-top) ul {

                & > li > a:not(.button),
                & > li > span:not(.button),
                & > li > span:not(.button)::after {
                    color: $c-white;
                }
            }
        }
    }

    body.page-light & {

        .nav-content .wrapper nav.header-top {

            a,
            ul > li > a:not(.button) {
                color: $c-text-light;
            }

            .header-top-right {

                a,
                ul > li > a:not(.button) {
                    color: $c-text;
                }
            }
        }
    }

    body.page-dark & {

        &.expanded {

            @media (min-width: $s-nav-burger-limit) {
                background-color: $bg-white;
            }
        }

        .nav-content .mega-nav {

            @media (min-width: $s-nav-burger-limit) {
                background-color: $bg-dark;
                color: $c-text-light;

                nav.menu ul li {
                    background-color: $bg-dark;

                    a,
                    span {
                        &:not(.button) {
                            background-color: $bg-dark;
                            color: $c-text-light;
                        }
                    }
                }
            }
        }
    }
}
