body.page-error {

    main {
        min-height: 650px;
        height: calc(100vh - 170px);
    }

    section.simple-text {
        height: 100%;
    }

    footer {
        min-height: 100px;
        background-color: $bg-footer;

        .pre-footer {
            display: none;
        }
    }

    .error-page-content {
        padding-bottom: 50px;
        text-align: center;
    }

    h1 {
        margin: 0 0 0px;
        font: {
            size: 5em;
            weight: 600;
        }
        line-height: 1.2em;

        @media (min-width: $s-mobile) {
            font-size: 10em;
        }
    }

    h2 {
        margin-bottom: 30px;
    }

    .text {
        margin-top: 50px;
    }
}