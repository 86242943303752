$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 21px !default;
$hamburger-bg-color            : transparent !default;
$hamburger-layer-width         : 25px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 6px !default; // Bar spacing
$hamburger-layer-color         : $c-dark !default; // Bar color
$hamburger-layer-color-top     : $hamburger-layer-color !default; // Top bar color
$hamburger-layer-color-bottom  : $hamburger-layer-color !default; // Bottom bar color
$hamburger-layer-border-radius : 4px !default; // Bar border radius
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Hamburger
// ==================================================
.hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 0.2s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    line-height: 0;
    text-transform: none;
    background-color: $hamburger-bg-color;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        }
        @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &.is-active {
        &:hover {
            @if $hamburger-hover-use-filter == true {
                filter: $hamburger-active-hover-filter;
            }
            @else {
                opacity: $hamburger-active-hover-opacity;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $hamburger-active-layer-color;
        }
    }
}

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    position:absolute;
    transition: transform 0.1s 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear;
    margin-top: - $hamburger-layer-height * 0.5;

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.2s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        right: 0;
        display: block;
    }

    &::before {
        //top: -8px;
        top: -($hamburger-layer-height + $hamburger-layer-spacing);
        transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        background-color: $hamburger-layer-color-top;
    }

    &::after {
        //bottom: -8px;
        bottom: -($hamburger-layer-height + $hamburger-layer-spacing);
        transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        background-color: $hamburger-layer-color-bottom;
    }

}

// Hamburger types
// ==================================================
.hamburger.is-active {
    .hamburger-inner {
        transform: rotate(90deg);
        transition-delay: 0.22s;
        background-color: transparent !important;
        transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.2s linear;

        &::before {
            //top: 0;
            //width: $hamburger-layer-width;
            //transition: top 0.1s 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            //transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
            //transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
            top: 0;
            transform: rotate(-45deg);
            transition: top 0.1s 0.1s ease-out, transform 0.1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &::after {
            //top: 0;
            //transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
            //transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
            //transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
            bottom: 0;
            transform: rotate(45deg);
            transition: bottom 0.1s 0.1s ease-out, transform 0.1s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }
}

.hamburger-box-custom {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;

    i {
        position: absolute;
        inset: 0;
        font-size: 20px;
        transition: opacity $t-duration, transform $t-duration;
    }

    .hamburger:not(.is-active) & {
        i.when-active {
            opacity: 0;
            transform: scale(0.5);
        }

        i.when-inactive {
            opacity: 1;
            transform: scale(1);
        }
    }

    .hamburger.is-active & {
        i.when-active {
            opacity: 1;
            transform: scale(1);
        }

        i.when-inactive {
            opacity: 0;
            transform: scale(0.5);
        }
    }

    .hamburger:hover & {

    }
}
