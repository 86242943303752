/* --------------------------------

Title: Language Picker
Descr: A custom selector allowing users to choose their preferred language on a page

-------------------------------- */

.locale-switcher {
    display: inline-block;
    position: relative;
}

/*.js*/ .locale-switcher__form { // if js is enabled we replace the default form with a custom dropdown
    display: none;
}

.locale-switcher__button { // created in js - dropdown trigger. Pass custom classes using the data-trigger-class attribute of the .locale-switcher element
    .icon { // dropdown arrow (edit inline SVG in JS file of component)
        height: 16px;
        width: 16px;
        margin-left: var(--space-xxxs);
    }
}

.locale-switcher__dropdown { // created in js - dropdown element with list of languages
    position: absolute;
    left: 0;
    top: 100%;
    //width: 200px;
    background-color: var(--color-bg);
    box-shadow: var(--shadow-sm);
    padding: var(--space-xxs) 0;
    border-radius: var(--radius-md);
    z-index: var(--zindex-popover);

    // reset spacing and text sizes
    //@include spaceUnit(1rem);
    font-size: 1rem;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .2s, opacity .2s, transform .2s ease-out;
}

.locale-switcher__button[aria-expanded="true"] + .locale-switcher__dropdown {
    visibility: visible;
    opacity: 1;
    transform: translateY(4px);
    transition: opacity .2s, transform .2s ease-out;
}

.locale-switcher__item {
    text-decoration: none;
    padding: var(--space-xs) var(--space-lg) var(--space-xs) var(--space-md);
    color: var(--color-contrast-high);

    span { // truncate text
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        background-color: var(--color-contrast-lower);
    }

    &[aria-selected=true] { // selected language
        position: relative;
        background-color: var(--color-primary);
        color: var(--color-white);
        //@include fontSmooth;

        &::after { // check icon next to the selected language
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 16px;
            width: 16px;
            right: var(--space-sm);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline stroke-width='1' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round' points='1,9 5,13 15,3 '/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}

.locale-switcher__flag { // create the icon flag element
    display: block; // flexbox fallback
    display: flex;
    align-items: center;

    &::before { // flag icon
        display: inline-block; // flex fallback
        flex-shrink: 0;
        content: '';
        height: 16px;
        width: 16px;
        margin-right: var(--space-xxs);
        // set flag as bg image
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .locale-switcher__dropdown &::before { // flag inside dropdown
        margin-right: var(--space-xs);
    }
}

// set the proper flag image
.locale-switcher__flag--deutsch::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath d='M48,18H0V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V18z'/%3E%3Crect y='18' fill='%23EE0000' width='48' height='12'/%3E%3Cpath fill='%23FDCF00' d='M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V30h48V40z'/%3E%3C/svg%3E");
}
.locale-switcher__flag--english::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%23002781' d='M46,6H2C0.896,6,0,6.896,0,8v32c0,1.104,0.896,2,2,2h44c1.104,0,2-0.896,2-2V8C48,6.896,47.104,6,46,6z'/%3E%3Cpath fill='%23E6E6E6' d='M48,8c0-1.104-0.896-2-2-2h-5.161L28,15.876V6h-8v9.876L7.161,6H2C0.896,6,0,6.896,0,8v2.586L12.239,20H0v8 h12.239L0,37.415V40c0,1.104,0.896,2,2,2h5.161L20,32.124V42h8v-9.876L40.839,42H46c1.104,0,2-0.896,2-2v-2.585L35.761,28H48v-8 H35.761L48,10.586V8z'/%3E%3Cpolygon fill='%23D10D24' points='48,22 26,22 26,6 22,6 22,22 0,22 0,26 22,26 22,42 26,42 26,26 48,26 '/%3E%3Cpath fill='%23D10D24' d='M47.001,6.307L29.2,20h3.28L48,8.062V8C48,7.268,47.587,6.656,47.001,6.307z'/%3E%3Cpath fill='%23D10D24' d='M32.48,28H29.2l17.801,13.693C47.587,41.344,48,40.732,48,40v-0.062L32.48,28z'/%3E%3Cpath fill='%23D10D24' d='M15.52,28L0,39.938V40c0,0.732,0.413,1.344,0.999,1.693L18.8,28H15.52z'/%3E%3Cpath fill='%23D10D24' d='M15.52,20h3.28L0.999,6.307C0.413,6.656,0,7.268,0,8v0.062L15.52,20z'/%3E%3C/svg%3E");
}
.locale-switcher__flag--francais::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%2301209F' d='M16,42H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h14V42z'/%3E%3Cpath fill='%23EF4234' d='M48,40c0,1.105-0.895,2-2,2H32V6h14c1.105,0,2,0.895,2,2V40z'/%3E%3Crect x='16' y='6' fill='%23E6E6E6' width='16' height='36'/%3E%3C/svg%3E");
}
.locale-switcher__flag--italiano::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%23009345' d='M16,42H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h14V42z'/%3E%3Cpath fill='%23CF2B36' d='M48,40c0,1.105-0.895,2-2,2H32V6h14c1.105,0,2,0.895,2,2V40z'/%3E%3Crect x='16' y='6' fill='%23E6E6E6' width='16' height='36'/%3E%3C/svg%3E");
}

// --hide-label
.locale-switcher--hide-label .locale-switcher__button {// hide language label in custom button -> show only flag and arrow icon
    .icon {
        margin-left: 0;
    }

    em { // label
        //display: none;
    }

    .locale-switcher__flag::before {
        margin-right: var(--space-xxxs);
    }
}