.video-picture {
    position: relative;

    &.no-picture {
        min-height: 150px;
        background-color: $c-gray;
    }
}

.video-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 18;
    top: 0;
    left: 0;

    .video-container {
        height: 100%;

        .video-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            a.button-video {
                height: 100%;

                &.hidden {
                    display: none;
                }

                &:before { /* LINK ENTIRE .video-box BLOCK */
                    content: '';
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .player {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

#video-popup {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    padding: 60px;
    z-index: 10000;
    background-color: #000;

    .popup-close {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        z-index: 1000;

        .burger-div {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: transparent;

            &:before, &:after {
                content: '';
                position: absolute;
                right: 0;
                width: 20px;
                height: 100%;
                transform: translateZ(0);
                backface-visibility: hidden;
                transition: transform .3s, background .3s;
                background-color: #fff;
            }

            &:before {
                transform: translateY(0px) rotate(45deg);
            }

            &:after {
                transform: translateY(0px) rotate(-45deg);
            }
        }
    }

    .video-popup-container {
        width: 100%;
        height: 100%;
        position: relative;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
