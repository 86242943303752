$section-pad-y-min: 25px !default;
$section-pad-y-max: 50px !default;
$bg-dark: $c-primary !default;
$bg-light: $c-light !default;
$c-text-dark: $c-dark !default;
$c-text-light: $c-white !default;

.section {
    padding: 2 * $section-pad-y-min 0;

    @media (min-width: $s-tablet) {
        padding: 2 * $section-pad-y-max 0;
    }

    background-position: center;

    &.bg-cover {
        background-size: cover;
    }

    &.bg-pattern {
        background-repeat: repeat;
    }

    &.bg-dark {
        background-color: $bg-dark;
        color: $c-text-light;

        a:not(.button), h1, h2, h3, h4, h5, h6 {
            color: $c-text-light;
        }

        // [in _button.scss]
        a.button,
        span.button,
        input.button,
        button.button { }

        // [in _forms.scss]
        form { }

        .content-block.list-content {

            ul.items li.item .title {
                color: $c-text-light;

                a {
                    color: $c-text-light;
                }
            }
        }

        .content-block.number-list-content {

            ul.number-list {
                li {
                    .number {
                        color: $c-white;
                    }
                }
            }
        }

        .content-block.faq {
            .questions {
                .faq-item {
                    //color: $c-text-light;
                }
            }
        }

        .content-block.custom-form-content {
            .ajax-message, .help-block {
                &.error,
                &.success {
                    color: $c-text-light;
                }
            }
        }

        .content-block.table{
            $accentBackground : $c-text;

            thead {
                background-color: $accentBackground;
            }

            tbody {
                tr {
                    &:first-child {
                        border-top-color: $accentBackground;
                    }

                    &:last-child {
                        border-bottom-color: $accentBackground;
                    }
                }
            }

        }
    }

    &.bg-light {
        background-color: $bg-light;
        color: $c-text-dark;
    }
}

.content-block {
    padding-top: 2 * $section-pad-y-min;

    @media (min-width: $s-tablet) {
        padding-top: 2 * $section-pad-y-max;
    }

    &.full-width, &.full-width-right, &.full-width-left {
        & > div {
            padding: {
                left: 20px;
                right: 20px;
            }
        }
    }

    &:first-of-type {
        padding-top: 0;
    }

    &:not(.bg-dark) + .content-block.bg-dark {
        margin-top: 2 * $section-pad-y-min;
        padding-top: 0;

        @media (min-width: $s-tablet) {
            margin-top: 2 * $section-pad-y-max;
            padding-top: 0;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.content-block.adv-content {

    & + .content-block.adv-content {
        //padding-top: 0;
    }

    &.bg-dark {
        background-color: $bg-dark;
        color: $c-text-light;

        a, h1, h2, h3, h4, h5, h6 {
            color: $c-text-light;
        }
        a.button,
        span.button,
        input.button,
        button.button {
            color: $c-text-light;

            .no-touchevents &:hover {
                color: $c-text-light;
            }
        }
    }
    &.bg-light {
        background-color: $bg-light;
        color: $c-text-dark;

        a, h1, h2, h3, h4, h5, h6 {
            color: $c-text-dark;
        }
    }

    &.no-picture{
        .content{
            @media (min-width: ($s-mobile)) {
                grid-column: span 2;
            }
        }
    }

    &.parallax {
        .picture {
            overflow: hidden;
        }
    }

    .picture {
        position: relative;
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;

        .parallax-underlay {
            width: 100%;

            @media (min-width: $s-mobile) {
                position: absolute;
                left: 0;
            }
        }

        img {
            //height: 100%;
            //object-fit: cover;
        }

        @media (min-width: $s-mobile) {
            min-height: 387px;

            .blog.detail &{
                min-height: 347px;
            }

            //.full-width-left &, .full-width-right & {
            //    min-height: 750px;
            //}

            //img {
            //    display: inline-block;
            //}
        }
    }

    .title{
        margin-bottom: 8px;
    }

    .site-content .site-half-content {
        //padding: 20px 0;
    }

    .site-half-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 20px 0;

        @media (min-width: $s-mobile) {
            padding: 40px;
        }

        @media (min-width: $s-tablet) {
            padding: 80px 50px;

            .blog.detail &{
                padding: 30px 0px;
            }
        }

    }

    & /*.full-width-right, &.full-width-left*/
    {
        @media (min-width: $s-mobile) {
            .picture {
                //background-size: cover;
                //background-position: center;
                overflow: hidden;

                & > img {
                    display: none;
                }
            }

            .site-half-content {
                //max-width: $site-width / 2;
            }
        }
    }

    &.full-width-right, &.right,
    &:nth-child(2n+1):not(.right):not(.full-width-right):not(.left):not(.full-width-left) {
        @media (min-width: $s-mobile) {
            .site-half-content {
                margin-left: auto;
            }
        }
    }

    &.full-width-left, &.left,
    &:nth-child(2n):not(.right):not(.full-width-right):not(.left):not(.full-width-left) {
        @media (min-width: $s-mobile) {
            .site-half-content {
                margin-right: auto;
            }
        }
    }

    @media (min-width: $s-mobile) {

        & > div {
            display: grid;
            align-items: center;
            text-align: left;
            grid-template-columns: repeat(1, 1fr);

            @media (min-width: ($s-mobile)) {
                grid-template-columns: repeat(2, 1fr);
                gap: 0 0;
            }
            @media (min-width: ($s-tablet)) {
                gap: 0 70px;

                .blog.detail &{
                    gap: 0 50px;
                }
            }
        }

        &.full-width, &.full-width-right, &.full-width-left {

            & > div {
                align-items: initial;
            }
        }

        .picture, .content {
        }

        &.full-width-right, &.right,
        &:nth-child(2n):not(.right):not(.full-width-right):not(.left):not(.full-width-left) {
            .picture {
                @media (min-width: ($s-mobile)) {
                    order: 2;
                }
            }
        }
    }

    .button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 30px;

        & > a,
        & > span {
            &:not(:last-child) {
                margin-bottom: 10px;

                @media (min-width: ($s-mobile)) {
                    margin-right: 20px;
                }

                @media (min-width: $s-tablet) {
                }
            }
        }
    }
}

.content-block.picture-content {
    img {
        width: 100%;
        max-width: 100%;
    }

    &.original {

        padding: {
            left: 20px;
            right: 20px;
        }

        img {
            width: auto;
        }
    }
}

.content-block.video-content {
    .site-content {
        position: relative;
    }
}

.content-block.html-content {
    iframe {
        display: block;
        margin: 0 auto;
    }
}

.content-block.title-content {

    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
    &.left {
        text-align: left;
    }

    h2, .h2,
    h3, .h3,
    h4, .h4 {
        margin-bottom: 0;
    }

    & + .content-block:not(.adv-content) {
        padding-top: 18px;
    }


    .blog.detail &{
        text-align: left;
    }
}

.content-block.text-content {

    .wysiwyg-content {
        margin: 0 auto;

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.button-list-content {
    text-align: center;

    .button {
        margin: 10px 30px
    }
}

.content-block.list-content {

    &.align-center {
        text-align: center;
    }

    &.align-left {
        text-align: left;

        ul.items li.item .picture img {
            margin-left: 0;
        }
    }

    &.align-right {
        text-align: right;

        ul.items li.item .picture img {
            margin-right: 0;
        }
    }

    .slider-content {
        margin: 0 -20px;
    }

    ul.items {
        list-style: none;
        padding: 0;
        margin: 0;

        li.item {
            vertical-align: top;
            padding: 20px 0;
            border: {
                style: solid;
                width: 0 20px;
                color: transparent;
            }

            .list-item {
                position: relative;
            }

            .picture {
                padding-bottom: 20px;
            }

            .title {
                font: {
                    family: $f-title;
                    weight: 700;
                    size: 20px;
                }
                line-height: 30px;
                color: $c-text;

                a {
                    color: $c-text;
                    text-decoration: none;
                }
            }

            .content + .button {
                margin-top: 10px;
            }
        }
    }

    &.slider {

        ul.items {
            margin: 0 auto;

            li.item {
                width: 240px;
                white-space: normal;

                .picture a {
                    display: inline-block;
                }
            }
        }
    }

    &.small-pictures {

        ul.items li.item .picture {
            img {
                max-width: 90px;
            }
        }
    }

    &.big-pictures {

        ul.items li.item .picture {
            img {
            }
        }
    }

    @media (min-width: (-$btn-slider-lg-size * 0.5) + 50px) {
        &.columns-2 {
            ul.items {
                li.item {
                    display: inline-block;
                    width: calc(100% / 2);
                }
            }
        }
    }

    @media (min-width: ($site-width * 0.33) + 50px) {
        &.columns-3 {
            ul.items {
                li.item {
                    display: inline-block;
                    width: calc(100% / 3);
                }
            }
        }
    }

    @media (min-width: ($site-width * 0.25) + 50px) {
        &.columns-4 {
            ul.items {
                li.item {
                    display: inline-block;
                    width: calc(100% / 4);
                }
            }
        }
    }

    @media (min-width: ($site-width * 0.2) + 50px) {
        &.columns-5 {
            ul.items {
                li.item {
                    display: inline-block;
                    width: calc(100% / 5);
                }
            }
        }
    }
}

.content-block.picture-list-content {
    text-align: center;

    .grid-sizer, .grid-item {
        width: 100%;
    }

    .grid-item {
        width: 100%;
        margin-bottom: 20px;

        img {
            width: 100%;
            display: block;
        }

        a {
            img {
                .no-touchevents & {
                    opacity: 1;
                    transition: opacity $t-duration;
                }
            }

            .no-touchevents &:hover {
                img {
                    opacity: 0.7;
                }
            }
        }
    }

    .gutter-sizer {
        width: 20px;
    }

    &.columns-2,
    &.columns-3,
    &.columns-4,
    &.columns-5 {
        .grid-sizer, .grid-item {
            width: calc((100% - 20px) / 2);
        }
    }

    @media (min-width: $s-mobile-md) {
        &.columns-3 {
            .grid-sizer, .grid-item {
                width: calc((100% - 40px) / 3);
            }
        }

        &.columns-4 {
            .grid-sizer, .grid-item {
                width: calc((100% - 60px) / 4);
            }
        }

        &.columns-5 {
            .grid-sizer, .grid-item {
                width: calc((100% - 80px) / 5);
            }
        }
    }
}

.content-block.custom-form-content {

    .bottom-text {
        font-size: 0.8em;
        line-height: 1.5;
    }
}

.content-block.map-content {
}

.content-block.number-list-content {

    ul.number-list {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;

        li {
            display: inline-block;
            vertical-align: top;
            width: 300px;
            margin-top: 30px;

            .number {
                font: {
                    family: $f-title;
                    size: 50px;
                    weight: 800;
                }
                line-height: 1.2;
                color: $c-primary;

                @media (min-width: $s-tablet) {
                    font-size: 60px;
                    line-height: 80px;
                }
            }

            .text {

                @media (min-width: $s-tablet) {
                    font-size: 22px;
                }
            }
        }
    }
}

.content-block.faq {

    .faq-item {
        background-color: white;
        padding: 25px 30px;
        border-radius: 10px;
        box-shadow: $shadowShort;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        .question {
            .title{
                color: $c-text;
                margin-bottom: 0;
            }

            & > i {
                display: none;
            }
        }

        .answer{
            color: $c-text;
            padding-top: 12px;
        }

    }

    &.collapsed {

        .faq-item {
            cursor: pointer;

            .question {
                position: relative;
                display: flex;

                .title{
                    margin-right: 12px;
                }

                & > i {
                    color: $c-text;
                    margin-left: auto;
                    height: auto;
                    align-self: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: rotate(0deg);
                    transition: transform $t-duration;
                }
            }

            .answer {
                display: none;
                transition: opacity $t-duration;
            }

            &.active {

                .question{
                    & > i {
                        transform: rotate(-180deg);
                    }
                }

            }
        }
    }
}

.content-block.step-list {

    .picture {
        margin-bottom: 20px;

        img{
            width: 100%;
        }
    }

    .step-list-items {
        margin: 0;
        padding: 0;
        list-style: none;

        .step-list-item {

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            .wysiwyg-content{
                word-break: break-word;
            }
        }
    }
}

.content-block.two-columns {

    & .site-content {
        max-width: 880px;
    }

    .line:not(:last-of-type) {
        margin-bottom: 25px;

        @media screen and (min-width: $s-mobile) {
            margin-bottom: 35px;
        }
    }

    .col1, .col2 {
        display: inline-block;
        vertical-align: top;
    }

    .col1 {
        width: 75%;
        text-align: left;
    }

    .col2 {
        width: 25%;
        text-align: right;
    }
}

.content-block.table {
    $textColor: $c-text;
    $accentBackground: $c-primary;
    $accentColor: $c-text-light;
    $cellPadding: 8px 16px;
    $rowBackground: $c-white;
    $rowBackgroundAlternate: darken($rowBackground, 4%);
    $rowBorder: darken($rowBackground, 12%);

    .table-wrapper {
        max-width: 880px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
        overflow-x: auto;
    }

    table {
        color: $textColor;
        width: 100%;
        border-collapse: collapse;
        overflow: hidden;
        //border-radius: 4px 4px 0px 0px;
        box-shadow: 0px 3px 8px 0px #00000014;
    }

    thead {
        background-color: $accentBackground;
        color: $accentColor;
        text-align: left;

        th {
            padding: $cellPadding;

        }
    }


    tbody {

        tr {
            border-bottom: 1px solid $rowBorder;
            background-color: $rowBackground;

            &.alt {
                background-color: $rowBackgroundAlternate;

            }

            &:first-child {
                border-top: 2px solid $accentBackground;
            }

            &:last-child {
                border-bottom: 2px solid $accentBackground;
            }
        }

        td {
            padding: $cellPadding;
        }
    }
}

.content-block.quote-content {
    .text{
        background: $bg-dark url('../images/pattern_dark.jpg');
        color: $c-text-light;
        padding: 40px 40px;
        font-size: 20px;
        font-weight: bold;

        @media (min-width: ($s-mobile)) {
            padding: 40px 50px;
            font-size: 25px;
        }

        &:before {
            content: "“";
        }

        &:after {
            content: "”";
        }
    }
}