.social-wall {

    .title {
        margin-bottom: 10px;
        text-align: center;
    }

    .text {
        margin-bottom: 20px;
        text-align: center;
    }

    nav.social-menu-container {
        margin-bottom: 20px;
        padding-top: 0;
    }

    .social-post-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -10px;

        @media (min-width: $s-mobile) {
            flex-wrap: nowrap;
        }
    }

    .social-post-item {
        position: relative;
        width: calc(50% - 20px);
        margin: 20px 10px 0;

        &:nth-child(-n + 2) {
            margin-top: 0;
        }

        @media (min-width: $s-mobile) {
            width: calc(20% - 16px);
            margin-top: 0;
        }

        .no-touchevents &.with-products:hover {

            .picture {
                opacity: 0.5;
            }
        }

        .picture {
            transition: opacity $t-duration;
        }
    }
}