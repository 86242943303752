$loader-width: 50px;
$loader-height: 50px;

.loader-container {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: $bg-white;
        opacity: 0;
        transition: opacity $t-duration;
        pointer-events: none;
    }

    &.loading {
        pointer-events: auto;

        &::before {
            opacity: 0.9;
        }

        .loader {
            opacity: 1;
        }

        &.loaded {

            &::before {
                opacity: 1;
            }
        }

        &.center {
            .loader {
                top: 50%;
                transform: translate(-50%, -50%);
                margin: 0 auto;
            }
        }

        &.bottom {
            .loader {
                top: auto;
                bottom: 200px;
            }
        }
    }
}
.loader {
    position: absolute;
    top: 200px;
    //left: calc(50% - 15px);
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    z-index: 2;
    width: $loader-width;
    height: $loader-height;
    opacity: 0;
    transition: opacity $t-duration;

    div {
        position: absolute;
        top: 0;
        left: 0;
        width: $loader-width;
        height: $loader-height;
        opacity: 1;
        background: {
            position: center;
            repeat: no-repeat;
            size: contain;
        }
        transform-origin: top;

        /* WITH IMAGE*/
        //background-image: url('../images/loader.png');

        /* WITHOUT IMAGE */
        background-color: $c-primary;
        border-radius: 100%;
    }

    &.opacity {

        div {
            animation: loaderOpacity 1.5s infinite ease-in-out;
        }
    }

    &.bounce {
        //animation: loaderBounceN 15s infinite linear;

        div {
            animation: 1.5s infinite cubic-bezier(0.42, 0, 0.58, 1.0);
            animation-name: loaderBounceN;
        }

        div:nth-child(1) {
        }
        div:nth-child(2) {
            animation-delay: -0.5s;
        }
        div:nth-child(3) {
            animation-delay: -1s;
        }
    }

    &.full-rotate {
        animation: loaderFullRotate 15s infinite linear;
    }

    &.rotate {

        div {
            animation: loaderRotate 1.5s infinite ease-in-out alternate-reverse;
        }
    }
}