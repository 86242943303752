@import "nice-select";
//@import "slim-select";
@import "~select2/dist/css/select2.css";
@import "select2";
@import "jquery-ui";

@import "~jquery-clockpicker/dist/jquery-clockpicker.css";


$form-field-pad-h: 6.5px !default;
$form-field-pad-w: 20px !default;
$form-field-border-width: 1px !default;
$form-field-border-radius: 5px !default;
$form-field-border-radius-small: 5px !default;
$form-field-label-font-f: Arial, sans-serif !default;
$form-field-label-font-s: 15px !default;
$form-field-text-font-f: Arial, sans-serif !default;
$form-field-text-font-s: 15px !default;
$form-field-line-height: 1.5 !default;
// [on light background]
$form-field-bg-color: #fff !default;
$form-field-bg-color-focus: #fff !default;
$form-field-border-color: #ccc !default;
$form-field-border-color-hover: transparentize(#ccc, 0.7) !default;
$form-field-text-color: #000 !default;
$form-field-placeholder-color: transparentize(#000, 0.7) !default;
// [on dark background]
$form-field-bg-color-bis: #000 !default;
$form-field-bg-color-bis-focus: #000 !default;
$form-field-border-color-bis: #000 !default;
$form-field-border-color-bis-hover: transparentize(#000, 0.7) !default;
$form-field-text-color-bis: #fff !default;
$form-field-placeholder-color-bis: transparentize(#fff, 0.7) !default;


input, textarea, select, button {
    &:focus {
        outline-width: 0;
    }

    &:-webkit-autofill {
        -webkit-animation-name: autofill;
        -webkit-animation-fill-mode: both;
    }
}

form,
.form {
    display: block;
    //max-width: 800px;
    margin: 0 auto;

    .row {
        @media (min-width: $s-mobile) {
            display: flex;
        }

        &.align-center {
            align-items: center;
        }

        &.optin-row {
            margin-bottom: 10px;

            @media (min-width: $s-mobile) {
                padding: 0 25px;
            }
        }

        .form-group {
            padding: 0;

            &.floating-label {

                &  > label {
                    left: 0; // form-group padding left
                }

                .form-control:not(.open-box):not(.nice-select):focus,
                .form-control:not(.open-box):not(.nice-select):not(:placeholder-shown),
                .open-box.selected,
                .form-control.nice-select.not-empty,
                .form-control.slim-select.not-empty,
                .select2-container.not-empty,
                .select2-container:focus-within,
                .select-container.not-empty,
                .select-container:focus-within {

                    & + label {
                        left: $form-field-pad-w - 10px;
                    }
                }
            }
        }

        .col {
            width: 100%;
            margin-bottom: 20px;

            @media (min-width: $s-mobile) {
                width: calc(50% - 10px);

                &:first-child {
                    margin-right: 10px;
                }

                &:not(:only-child):last-child {
                    margin-left: 10px;
                }
            }

            .help-block {
                text-align: left;
            }
        }

        .full {
            width: 100%;
            text-align: center;
            margin-bottom: 20px;

            .radio, .checkbox {
                margin-left: 0;
                text-align: left;

                label {
                    align-items: flex-start;
                    margin-left: 0;
                    padding-right: 0;

                    i[class^=icon-] {
                        width: inherit;
                        line-height: $form-field-label-font-s + 5px;

                        @media (min-width: $s-mobile) {
                            line-height: $form-field-label-font-s + 10px;
                        }
                    }
                }
            }

            label + div {
                display: inline-block;
            }

            .help-block {
                text-align: center;
            }
        }

        .form-group {
            line-height: 20px;
        }
    }

    .datepicker-row,
    .clockpicker-row {
        position: relative;

        input.form-control {

            & + i {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 50px;
                height: $form-field-pad-h * 4 + $form-field-border-width * 2; // input height
                transition: color $t-duration;
                pointer-events: none;
            }

            &:focus + i {
                color: $c-primary;
            }
        }
    }

    .actions {
        text-align: center;
    }

    .form-group {
        //position: relative;
        padding: 0 10px 20px;
        vertical-align: top;
        width: 100%;

        @media screen and (min-width: $s-mobile) {
            display: inline-block;

            &.length-small {
                width: 50%;
            }
        }
    }

    label {
        display: inline-block;
        padding-right: 20px;
        font: {
            family: $form-field-label-font-f;
            size: $form-field-label-font-s;
            weight: bold;
            //style: italic;
        }
        line-height: $form-field-label-font-s + 5px;
        color: $form-field-text-color;
        text-transform: uppercase;
        letter-spacing: 0.2px;

        @media (min-width: $s-mobile) {
            line-height: $form-field-label-font-s + 10px;
        }

        // * to add in form labels
        //&.required {
        //    &::after {
        //        content: ' *';
        //        color: #ff0000;
        //    }
        //}
    }

    .form-control {
        width: 100%;
        padding: $form-field-pad-h $form-field-pad-w;
        background: none;
        background-color: $form-field-bg-color;
        -webkit-appearance: none;
        border: {
            style: solid;
            width: $form-field-border-width;
            color: $form-field-border-color;
            radius: $form-field-border-radius;
        }
        box-shadow: none;
        font: {
            family: $form-field-text-font-f;
            size: $form-field-text-font-s;
        }
        line-height: $form-field-pad-h * 2;
        color: $form-field-text-color;
        transition: border-color $t-duration;

        box-shadow:
                inset 3.3px 3.3px 2.2px 0 rgba(174, 174, 192, 0.2), // Top left inner shadow
                inset -2.2px -2.2px 2.2px 0 rgba(255, 255, 255, 0.7), // Bottom right inner highlight
                -1px -1px 3px 0 #fff, // Top left outer highlight
                1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.2); // Bottom right outer highlight

        .no-touchevents &:hover {
            border-color: $form-field-border-color-hover;
        }

        &::placeholder,
        &[type="date"]:invalid::-webkit-datetime-edit,
        &[type="time"]:invalid::-webkit-datetime-edit {
            //height: $form-field-pad-h * 2;
            font: {
                family: $form-field-text-font-f;
                size: $form-field-text-font-s;
            }
            color: $form-field-placeholder-color;
        }

        &[type="date"],
        &[type="time"] {
            position: relative;

            &::-webkit-datetime-edit-fields-wrapper {
                text-transform: uppercase;
            }

            &::-webkit-calendar-picker-indicator {
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 100%;
                cursor: pointer;
                background: none;
            }

            &::after {
                display: inline-block;
                font-family: $f-icons;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            &:focus {
                &::after {
                    color: $c-primary;
                }
            }
        }

        &[type="date"] {

            &::after {
                content: map-get($pure-icons-map, 'calendar');
            }
        }

        &[type="time"] {

            &::after {
                content: map-get($pure-icons-map, 'time');
            }
        }

        &:focus {
            background: $form-field-bg-color-focus;
            border-color: $form-field-border-color-hover;
            outline: 0;
        }

        &.error {
            box-shadow: 0 0 7px $c-error inset;
        }

        &[readonly="readonly"] {
            &,
            &:focus,
            &:hover {
                background-color: $form-field-border-color;
                border-color: $form-field-border-color;
                cursor: default;
            }
        }

    }

    textarea.form-control {
        height: 175px;
        border-radius: $form-field-border-radius-small;
    }

    .select-container {
        position: relative;
        overflow: hidden;

        &::after {
            content: '\0043';
            color: $form-field-text-color;
            display: inline-block;
            font-family: $f-icons;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: color $t-duration, transform $t-duration;
            position: absolute;
            //bottom: 12px;
            bottom: calc(50% - 10px);
            right: 20px;
            width: 15px;
            font-size: 18px;
            pointer-events: none;
        }

        &:hover {

            &::after {
                color: $c-primary;
            }
        }

        //&:focus, &:focus-within {
        //
        //    &::after {
        //        transform: rotate(-180deg);
        //    }
        //}

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            padding-right: 30px;
            height: auto;
            max-height: 100%;
            margin: 0;
            //line-height: ($form-field-pad-h * 2) - 2px;
            line-height: ($form-field-pad-h * 2);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    select.form-control {
        option {
            //color: pink;
        }
    }

    .has-error .form-control {
        border-color: $c-error;
        color: $c-error;

        &::placeholder,
        &[type="date"]::-webkit-datetime-edit-fields-wrapper,
        &[type="time"]::-webkit-datetime-edit-fields-wrapper {
            color: $c-error;
        }
    }

    .radio,
    .checkbox {
        display: inline-block;
        //margin-left: 15px;
        line-height: 20px;

        &.has-error {

            &, & label, & i[class^=icon-], & a {
                color: $c-error;
            }
        }

        .no-touchevents &:hover {
            label i[class^=icon-],
            label i.fa {
                color: $c-primary;
            }
        }

        label {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
            color: $form-field-text-color;

            &:not(:first-of-type) {
                margin-left: 10px;
            }

            input {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                width: 20px;
                margin: 0;
            }

            i[class^=icon-],
            i.fa {
                //width: 20px;
                height: 15px;
                margin-right: 10px;
                font-size: 15px;
                line-height: 15px;
                color: $form-field-text-color;
                transition: color $t-duration;

                &.icon-checkbox-checked,
                &.icon-radio-checked,
                &.fa-dot-circle-o {
                    color: $c-primary;
                }
            }
            input {
                opacity: 0;
            }
        }
    }

    .radio,
    .checkbox,
    .radio_list,
    .checkbox_list {

        .help-block ul {
            padding-left: 0;
        }
    }

    div.floating-label {
        position: relative;

        & > label {
            position: absolute;
            z-index: 1;
            top: $form-field-border-width;
            left: $form-field-border-width;
            padding: $form-field-pad-h $form-field-pad-w;
            line-height: $form-field-pad-h * 2;
            color: $form-field-placeholder-color;
            transition: all $t-duration * 0.5;
            opacity: 0;
            pointer-events: none;
        }

        &.form-group > label {
            left: 10px; // form-group padding left
        }

        .form-control::placeholder,
        .select2-selection__placeholder {
            opacity: 1;
            transition: all $t-duration * 0.5;
        }

        .form-control:focus::placeholder,
        .select2-container:focus-within .select2-search__field::placeholder {
            font-size: 75%;
            color: transparent;
            opacity: 0;
            transform: translateY(-10px);
        }

        .form-control:not(.open-box):not(.nice-select):not(.slim-select):focus,
        .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown),
        .open-box.selected,
        .form-control.nice-select.not-empty,
        .form-control.slim-select.not-empty,
        .select2-container.not-empty .select2-selection,
        .select2-container:focus-within .select2-selection {
            background: $form-field-bg-color-focus;
        }

        .form-control:not(.open-box):not(.nice-select):not(.slim-select):focus,
        .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown),
        .open-box.selected,
        .form-control.nice-select.not-empty,
        .form-control.slim-select.not-empty,
        .select2-container.not-empty,
        .select2-container:focus-within,
        .select-container.not-empty,
        .select-container:focus-within {

            & + label {
                top: -$form-field-pad-h;
                left: $form-field-pad-w - 10px;
                padding: 0 10px;
                font-size: 75%;
                opacity: 1;

                // Style 1
                //background-color: $form-field-bg-color;
                //color: $form-field-text-color;

                // Style 2
                //background-color: $c-primary;
                //background-color: $c-second;
                //color: $c-white;
                //border-radius: 100px;

                //Style 3
                //background-color: $form-field-bg-color;
                //color: $form-field-text-color;
                //border-radius: 100px;

                //Style 4
                background-color: transparent;
                color: $form-field-text-color;

                &::after {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 50%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: $form-field-border-width;
                    background-color: $form-field-border-color-hover;
                }
            }
        }

        //.form-control:not(.open-box):not(.nice-select):focus,
        //.form-control:not(.open-box):not(.nice-select):hover,
        //.select-container:focus-within,
        //.select-container:hover {
        //    & + label {
        //
        //        &::after {
        //            background-color: $form-field-border-color-hover;
        //        }
        //    }
        //}

        &.form-group {

            .form-control:not(.open-box):not(.nice-select):not(.slim-select):focus,
            .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown),
            .open-box.selected,
            .form-control.nice-select.not-empty,
            .form-control.slim-select.not-empty,
            .select2-container.not-empty,
            .select2-container:focus-within,
            .select-container.not-empty,
            .select-container:focus-within {

                & + label {
                    left: $form-field-pad-w;
                }
            }
        }

        .select-container {

            & + label {
                opacity: 1;
            }

            &:not(.not-empty) {

                .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown) {
                    background-color: $form-field-bg-color;
                }

                & + label {
                    //font: {
                    //    family: $form-field-label-font-f;
                    //    size: $form-field-label-font-s;
                    //}
                    color: $form-field-placeholder-color;
                }
            }

            &:focus-within {

                .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown) {
                    background-color: $form-field-bg-color-focus;
                }

                & + label {
                    color: $form-field-text-color;
                }
            }
        }
    }

    .mention {
        font-size: 10px;
        line-height: 15px;
        opacity: 0.6;
        color: $c-text;
        text-align: center;
    }

    .ajax-message,
    .help-block {
        margin: 10px auto 20px;
        font-size: 13px;
        line-height: 13px;

        &.error {
            color: $c-error;
        }

        &.success {
            color: $c-text;
            font-size: 15px;
            line-height: 20px;
        }
    }

    .help-block {
        display: block;
        color: $c-error;

        @media (min-width: $s-mobile) {
            margin: 10px auto 0;
        }

        ul {
            list-style: none;
            //padding: 0 $form-field-pad-w;
            padding: 0;
            margin: 0;
        }
    }

    .user-dropzone {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: left;

        @media (min-width: $s-mobile-md) {
            flex-wrap: wrap;
            flex-direction: row;
        }

        .dz-placeholder {
            order: 1;
            position: relative;
            display: none;
            align-items: center;
            padding-left: $form-field-pad-w + 5px;

            @media (min-width: $s-mobile-md) {
                width: 100%;
            }

            &.visible {
                display: flex;
            }

            & > i {
                position: absolute;
                //left: -25px;
                left: 0;
                //margin-right: 10px;
                line-height: 10px;
                color: $form-field-placeholder-color;
            }

            span.placeholder-text {
                font-family: $form-field-text-font-f;
                font-size: 15px;
                line-height: $form-field-pad-h*2;
                color: $form-field-placeholder-color;
            }
        }

        &.dz-clickable, .dz-clickable {
            cursor: pointer;
        }

        &.dz-drag-hover {
            background-color: transparentize($c-primary, 0.5);

            .preview {
                opacity: 0.5;
            }

            .dz-placeholder {
                opacity: 0.5;
            }
        }

        .dz-loader {
            order: 2;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;
            flex-direction: column;
            align-items: center; // Comment for cool anim
            justify-content: center;
            background-color: transparentize($form-field-bg-color, 0.3);

            &.visible {
                display: flex;
            }

            & > i {
                font-size: 2em;
            }
        }

        .dz-preview {
            order: 3;
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 100%;
            max-width: 100%;
            min-height: 100px;
            margin: 10px 10px 10px 0;
            padding: 10px 10px 10px 100px;
            text-align: center;
            border: 1px solid $c-primary;
            border-radius: 4px;
            cursor: default;

            @media (min-width: $s-mobile-md) {
                max-width: 120px;
                padding: 10px;
            }

            .dz-image {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                position: absolute;
                top: 10px;
                left: 10px;
                width: 80px;
                //height: 60px;
                height: calc(100% - 20px);
                margin: 0 auto;

                @media (min-width: $s-mobile-md) {
                    position: static;
                    height: 60px;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                }

                i.fa {
                    font-size: 60px;
                    color: $c-primary;
                }
            }

            .dz-details {
                margin: 10px auto;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                word-break: break-all;
            }

            .dz-error-message {
                margin-bottom: 10px;
                font-size: 12px;
                line-height: 15px;
                color: $c-error;
            }

            .dz-success-mark, .dz-error-mark {
                display: none;
            }

            .dz-remove {
                display: inline-flex;
                align-items: center;
                font-size: 15px;
                line-height: 15px;
                color: $c-second;
                text-decoration: none;

                @media (min-width: $s-mobile-md) {
                    font-size: 10px;
                    line-height: 12px;
                }

                .no-touchevents &:hover {
                    color: $c-primary;
                    text-decoration: none;
                }

                &::before {
                    margin-right: 5px;
                    //font: normal normal normal 20px/1 FontAwesome;
                    //content: '\f014';
                    //display: inline-block;
                    //text-rendering: auto;
                    //-webkit-font-smoothing: antialiased;
                    //-moz-osx-font-smoothing: grayscale;
                    font-family: $f-icons;
                    content: "\0054";
                    display: inline-block;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;

                    @media (min-width: $s-mobile-md) {
                        font-size: 15px;
                    }
                }
            }
        }

        .dz-add-files {
            order: 4;
            display: none;
            align-items: center;
            margin: 0;
            padding: 10px 15px;
            font-size: 15px;
            border: 1px dashed $form-field-border-color;
            border-radius: 5px;
            transition: {
                property: border-color, color;
                duration: $t-duration;
            }

            @media (min-width: $s-mobile-md) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 120px;
                margin: 10px 10px 10px 0;
                text-align: center;
                line-height: 20px;
            }

            &.visible {
                display: flex;
            }

            .no-touchevents &:hover {
                color: $c-primary;
                border-color: $c-primary;
            }

            & > i {
                margin-right: 10px;

                @media (min-width: $s-mobile-md) {
                    margin: {
                        right: 0;
                        bottom: 10px;
                    }
                }
            }
        }

        &.custom-form-dropzone {

            &.dz-max-files-reached {
                //padding-left: 20px;
            }

            .dz-preview {
                min-height: inherit;

                &.visible {
                    display: inline-block;
                }
            }
        }

        .preview-media {
            display: none;

            &.visible {
                display: block;
            }
        }
    }
}

.dropdown {
    position: relative;
    max-width: 100%;

    @media (min-width: $s-mobile-sm) {
        width: max-content;
    }

    button.button.dropdown-toggle {
        padding: 10px 15px;

        @media (min-width: $s-mobile-sm) {
            height: 50px;
        }

        & > i.icon-plus, & > i.icon-arrow-angle-down {

            &:first-of-type {
                margin-left: 0;
                margin-right: 10px;
            }

            &:last-of-type {
                margin-left: 10px;
                margin-right: 0;
            }
        }
    }

    .dropdown-menu {
        list-style: none;
        position: absolute;
        top: calc(100% + 10px);
        transform: translateY(-5px);
        left: 0;
        width: 100%;
        min-width: max-content;
        max-width: 500px;
        margin: 0;
        padding: 0;
        background-color: $bg-white;
        border-radius: 5px;
        box-shadow: 0 10px 15px 0 transparentize($c-dark, 0.85);
        opacity: 0;
        transition: opacity $t-duration, transform 2*$t-duration;
        pointer-events: none;

        &.visible {
            opacity: 1;
            transform: translateY(0);
            pointer-events: initial;
        }

        li {
            margin: 0;
            padding: 0;

            a {
                display: block;
                width: 100%;
                padding: 10px 15px 10px 25px;
                font-weight: 700;

                .no-touchevents &:hover, .no-touchevents &:focus, .no-touchevents &:focus-within {
                    background-color: $c-primary;
                    color: $c-white;
                }

                & > i {
                    margin: {
                        left: 0;
                        right: 5px;
                    }
                    font-size: 12px;
                }
            }
        }
    }
}

.show-on-error {
    text-align: center;
    color: $c-error;
    display: none;
    padding: 20px 10px;
}

.show-on-success {
    text-align: center;
    color: $c-success;
    display: none;
    padding: 20px 10px;
}






.bg-dark,
section.bg-dark {

    form,
    .form {

        label {
            color: $c-white;
        }

        .form-control {
            background-color: $form-field-bg-color-bis;
            color: $form-field-text-color-bis;
            border-color: $form-field-border-color-bis;

            .no-touchevents &:hover {
                border-color: $form-field-border-color-bis-hover;
            }

            &::placeholder,
            /*&[type="date"]::-webkit-datetime-edit-fields-wrapper,
            &[type="time"]::-webkit-datetime-edit-fields-wrapper*/ {
                color: $form-field-placeholder-color-bis;
            }

            &:focus {
                background: $form-field-bg-color-bis-focus;
                border-color: $form-field-border-color-bis-hover;
            }

            &[readonly="readonly"] {
                &,
                &:focus,
                &:hover {
                    background-color: $form-field-border-color-bis;
                    border-color: $form-field-border-color-bis;
                }
            }
        }

        .radio, .checkbox {

            label {
                color: $c-white;

                i[class^=icon-],
                i.fa {
                    color: $c-white;
                }
            }

            .no-touchevents &:hover {
                label i[class^=icon-],
                label i.fa {
                    color: $c-text;
                }
            }
        }

        div.floating-label {

            & > label {
                color: $form-field-placeholder-color-bis;
            }

            .form-control:not(.open-box):not(.nice-select):not(.slim-select):focus,
            .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown),
            .open-box.selected,
            .form-control.nice-select.not-empty,
            .form-control.slim-select.not-empty,
            .select2-container.not-empty .select2-selection,
            .select2-container:focus-within .select2-selection {
                background: $form-field-bg-color-bis-focus;
            }

            .form-control:not(.open-box):not(.nice-select):not(.slim-select):focus,
            .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown),
            .open-box.selected,
            .form-control.nice-select.not-empty,
            .form-control.slim-select.not-empty,
            .select2-container.not-empty,
            .select2-container:focus-within,
            .select-container.not-empty,
            .select-container:focus-within {

                & + label {

                    // Style 1
                    //background-color: $form-field-bg-color-bis;
                    //color: $form-field-text-color-bis;

                    // Style 2

                    //Style 3
                    //background-color: $form-field-bg-color-bis;
                    //color: $form-field-text-color-bis;
                    //border-radius: 100px;

                    //Style 4
                    background-color: transparent;
                    color: $form-field-text-color-bis;

                    &::after {
                        background-color: $form-field-border-color-bis-hover;
                    }
                }
            }

            //.form-control:not(.open-box):not(.nice-select):focus,
            //.form-control:not(.open-box):not(.nice-select):hover,
            //.select-container:focus-within,
            //.select-container:hover {
            //    & + label {
            //
            //        &::after {
            //            background-color: $form-field-border-color-bis-hover;
            //        }
            //    }
            //}

            .select-container {

                &:not(.not-empty) {

                    .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown) {
                        background-color: $form-field-bg-color-bis;
                    }

                    & + label {
                        color: $form-field-placeholder-color-bis;
                    }
                }

                &:focus-within {

                    .form-control:not(.open-box):not(.nice-select):not(.slim-select):not(:placeholder-shown) {
                        background-color: $form-field-bg-color-bis-focus;
                    }

                    & + label {
                        color: $form-field-text-color-bis;
                    }
                }
            }
        }

        .mention {
            color: $c-text-light;
        }

        .user-dropzone {

            .dz-placeholder {

                & > i,
                span.placeholder-text {
                    color: $form-field-placeholder-color-bis;
                }
            }

            .dz-loader {
                background-color: transparentize($form-field-bg-color-bis, 0.3);
            }

            .dz-add-files {
                border-color: $form-field-border-color-bis;
            }
        }
    }
}

.bg-light,
section.bg-light {

    form,
    .form {

        .mention {
            color: $c-text-dark;
        }
    }
}