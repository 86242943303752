.lightboxOverlay {
    opacity: 1;
    background-color: transparentize($c-dark, 0.1);
}

$maxWidth: calc(100vw - 270px); // 270px = 2 * (50px + 35px [btn width] + 50px)
.lightbox {

    //@media (min-width: $s-mobile) {
    //    max-width: $maxWidth;
    //    left: 50% !important;
    //    transform: translateX(-50%);
    //}

    .lb-outerContainer {

        @media (min-width: $s-mobile) {
            //max-width: $maxWidth;

            .lb-image {
                //max-width: $maxWidth;
            }
        }
    }

    a.lb-prev,
    a.lb-next,
    a.lb-close {
        @extend .button;
        @extend .button-neutral;
        width: 35px;
        height: 35px;
        min-height: inherit;
        padding: 0;
        opacity: 1;
        background: $c-gray-light;
        transition: {
            property: background-color, border-color, color, box-shadow;
            duration: $t-duration;
        }

        &::before {
            font: {
                family: $f-icons;
                size: 12px;
            }
        }
    }

    .lb-nav {

        a.lb-prev,
        a.lb-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        a.lb-prev {
            left: 10px;

            @media (min-width: $s-mobile) {
                right: calc(100% + 50px);
                left: auto;
            }

            &::before {
                content: map-get($pure-icons-map, "arrow-left");
            }
        }

        a.lb-next {
            right: 10px;

            @media (min-width: $s-mobile) {
                left: calc(100% + 50px);
                right: auto;
            }

            &::before {
                content: map-get($pure-icons-map, "arrow-right");
            }
        }
    }

    .lb-data .lb-close {
        position: absolute;
        z-index: 11;
        top: 10px;
        right: 20px;

        @media (min-width: $s-mobile) {
            position: fixed;
            //top: auto;
            //bottom: calc(100% + 50px);
            //right: auto;
            //left: calc(100% + 50px);
            top: 30px;
            right: 30px;
        }

        &::before {
            content: map-get($pure-icons-map, "cross");
        }
    }
}