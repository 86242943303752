.breadcrumb-container {
    .touchevents & {
        position: relative;

        &::before,
        &::after {
            position: absolute;
            z-index: 1;
            top: 0;
            height: #{$f-size-base * $f-line-height-base};
            background-color: $c-light;
            font-family: $f-primary;
            color: $c-text;
            opacity: 1;
            transition: opacity $t-duration;
            pointer-events: none;
        }

        &::before {
            content: '';
            left: 0;
            background: linear-gradient(-90deg, transparentize($c-light, 1) 0px, transparentize($c-light, 0) 15px);
            padding-right: 15px;
        }

        &::after {
            content: '...';
            right: 0;
            background: linear-gradient(90deg, transparentize($c-light, 1) 0px, transparentize($c-light, 0) 15px);
            padding-left: 15px;
        }

        &.scroll-min {
            &::before {
                opacity: 0;
            }
        }

        &.scroll-max {
            &::after {
                opacity: 0;
            }
        }
    }
}

.breadcrumb {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    font-size: 12px;

    .touchevents & {
        //white-space: nowrap;
        overflow-scrolling: touch;
        overflow-x: scroll;
    }

    li {
        position: relative;
        display: inline-block;
        margin: 0 10px 0 0;
        padding: 0 0px 0 0;

        &:not(:last-of-type)::after {
            content: '/';
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translateY(-50%);
            height: 10px;
            line-height: 10px;
            color: $c-text;
        }

        a {
            font-weight: 300;
            color: $c-text;
            text-decoration: none;

            .no-touchevents &:hover {
                color: $c-primary;
                text-decoration: none;
            }

            i{
                position: relative;
                bottom: -1px;
            }
        }
    }

    .page-dark &,
    .bg-dark & {

        li {
            color: $c-text-light;

            &:not(:last-of-type)::after {
                color: $c-text-light;
            }

            a {
                font-weight: 400;
                color: $c-text-light;

                .no-touchevents &:hover {
                    color: $c-primary;
                }
            }
        }
    }
}

.banner-content {

    .title-and-breadcrumb {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}