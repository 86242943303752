.ui-dialog {
	padding: 0;

	.ui-dialog-content {
		padding: 0;
		overflow: visible;
	}

	.ui-dialog-titlebar {
		display: none;
	}
}

.ui-widget.ui-widget-content {
	border-radius: 0;
	border: 0;
}

.ui-widget-overlay {
	background-color: rgba(0, 0, 0, 0.75);
	opacity: 1;
	z-index: 10002;
}


.ui-widget.ui-widget-content.ui-autocomplete {
	background-color: $bg-white;
	border: {
		width: 0;
		radius: $form-field-border-radius;
	}

	box-shadow:
			1.5px 1.5px 5px 0 transparentize(#AEAEC0, 0.6),
			-1px -1px 3px 0 $c-white,
			inset 0 0 0 0.2px transparentize($c-white, 0.8)
	;

	max-height: 250px;
	padding: 10px 0;

	box-sizing: border-box;
	overflow: hidden;
	overflow-y: auto;
	transform: translateY(10px);

	font-family: $form-field-text-font-f;
	font-size: $f-size-base;

	.ui-menu-item {
		min-height: 40px;
		padding: 10px 20px;
		font-weight: 400;
		line-height: 1;
		list-style: none;
		text-align: left;
		transition: all 0.2s;

		&:hover {
			margin: 0;
			border: none;
			background-color: transparent;
			color: $c-primary;

			.ui-menu-item-wrapper {
				color: $c-primary;
			}
		}

		&.ui-state-active,
		&.ui-state-focus,
		.ui-state-active,
		.ui-state-focus {
			margin: 0;
			border: none;
			background-color: transparent;
			color: $c-text;
		}

		mark.ui-menu-item-wrapper {
			padding: 0;
			font: {
				family: $form-field-text-font-f;
				weight: bold;
				size: $f-size-base;
			}
			background: none;
			transition: all 0.2s;
		}
	}
}


.ui-widget.ui-widget-content.ui-datepicker {
	transform: translateY(10px);
	padding: 5px;

	background-color: $bg-white;
	border: {
		width: 0;
		radius: $form-field-border-radius;
	}

	box-shadow:
			1.5px 1.5px 5px 0 transparentize(#AEAEC0, 0.6),
			-1px -1px 3px 0 $c-white,
			inset 0 0 0 0.2px transparentize($c-white, 0.8)
	;

	@media (max-width: $s-mobile-md - 1px) {
		width: calc(100vw - 2 * $site-width-pad-x);
	}

	.ui-widget-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		//height: 40px;
		padding: 5px 10px;
		font-weight: normal;
		color: $c-text;
		background-color: $c-second;
		border: {
			width: 0;
			radius: 5px;
		}

		$btn-size: 16px;

		.ui-datepicker-prev,
		.ui-datepicker-next {
			position: relative;
			//top: 10px;
			top: auto;
			width: $btn-size;
			height: $btn-size;

			&.ui-state-hover,
			&.ui-state-focus,
			&:hover,
			&:focus {
				background: none;
				border: none;
				color: inherit;

				.ui-icon::before {
					color: $c-primary;
				}
			}

			.ui-icon {
				position: relative;
				top: auto;
				left: auto;
				margin: 0;
				width: $btn-size;
				height: $btn-size;
				background-image: none;
				text-indent: inherit;

				&::before {
					//position: absolute;
					//top: 0;
					//left: 0;
					//width: 100%;
					//height: 100%;
					font: {
						family: $f-icons;
						size: $btn-size;
						weight: normal;
					}
					line-height: 1;
					color: $c-text;
					text-indent: inherit;
					transition: color $t-duration;
				}
			}
		}

		.ui-datepicker-prev {
			order: 1;
			//left: 10px;
			left: auto;

			.ui-icon::before {
				content: map-get($pure-icons-map, 'arrow-left');
			}
		}

		.ui-datepicker-next {
			order: 3;
			//right: 10px;
			right: auto;

			.ui-icon::before {
				content: map-get($pure-icons-map, 'arrow-right');
			}
		}

		.ui-datepicker-title {
			order: 2;
			flex-grow: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			width: 100%;
			margin: 0;
			padding: 0 10px;

			select.ui-datepicker-month,
			select.ui-datepicker-year {
				max-width: max-content;
				margin: 0;
				padding: 0 5px 0 0;
				font: {
					family: $f-text;
					size: 1em;
				}
				color: $c-text;
				line-height: 1;
				background-color: $bg-white;
				border: 5px solid $c-white;
			}
		}
	}

	.ui-datepicker-calendar {
		margin-bottom: 0;

		.ui-state-default {
			padding: 3px;
			color: $c-text;
			text-align: center;
			background-color: $c-light;
			border: {
				radius: 5px;
				//width: 0;
				color: $c-border;
			}

			&.ui-state-hover {
				color: $c-primary;
			}

			&.ui-state-highlight {
				color: $c-light;
				background-color: $c-primary;
				border-color: $c-primary;
			}
		}
	}
}


.jqclockpicker-container {
	transform: translateY(10px);
	padding: 5px;

	background-color: $bg-white;
	border: {
		width: 0;
		radius: $form-field-border-radius;
	}

	box-shadow:
			1.5px 1.5px 5px 0 transparentize(#AEAEC0, 0.6),
			-1px -1px 3px 0 $c-white,
			inset 0 0 0 0.2px transparentize($c-white, 0.8)
	;

	.jqclockpicker-header {
		color: $c-primary;
		background-color: $c-second;
		border: {
			width: 0;
			radius: 5px;
		}

		.jqclockpicker-active {
			color: $c-primary-lighter;
		}
	}

	.jqclockpicker-canvas-bearing,
	.jqclockpicker-canvas-fg {
		fill: $c-primary;
	}

	.jqclockpicker-canvas line {
		stroke: $c-primary;
	}

	.jqclockpicker-canvas-bg {
		fill: transparentize($c-primary, 0.75);
	}

	.jqclockpicker-tick {
		color: $c-text;

		&.active,
		&:hover {
			background-color: transparentize($c-primary, 0.75);
		}
	}
}