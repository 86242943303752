@use "sass:math";

$s-absolute-slider-btn: $s-desktop !default;
$link-text-color: $c-text !default;
$link-text-color-hover: $c-text !default;
$btn-text-font-f: $f-text !default;
$btn-text-font-s: $f-size-base !default;
$btn-text-color: $c-text !default;
$btn-text-color-hover: #fff !default;
$btn-border-width: 2px !default;
$btn-border-color: $c-primary !default;
$btn-border-color-hover: $c-primary !default;
$btn-border-radius: 0px !default;
$btn-bg-color: $c-primary !default;
$btn-bg-color-hover: $c-primary !default;
$btn-min-h: 40px !default;
$btn-pad-h: ($btn-min-h - $btn-text-font-s) * 0.5 !default;
$btn-pad-w: 20px !default;
$btn-slider-sm-size: 45px !default;
$btn-slider-lg-size: 60px !default;


$btn-box-shadow: 1.5px 1.5px 5px 0 rgba(#000, 0.1), // Bottom right external shadow
    inset 0px 0px 0px 0 transparent,                // Top left inner shadow
    inset 0px 0px 0px 0 transparent;                // Bottom right inner highlight

$btn-box-shadow-hover: 0px 0px 0px 0 transparent,   // Bottom right external shadow
    inset 4px 4px 6px 0 rgba(#000, 0.1),            // Top left inner shadow
    inset -2px -2px 6px 0 rgba(#fff, 0.5);          // Bottom right inner highlight

a,
a.button,
span.button,
input.button,
button.button {
    cursor: pointer;

    &.block-link {
        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.read {
        font: {
            family: $f-text;
            weight: bold;
        }
        color: $c-text;
        text-decoration: none;

        &:not([class*='with-picto']),
        &[class*='with-picto'] > span {
            border-bottom: 2px solid $c-primary;
            transition: {
                property: color, border-color;
                duration: $t-duration;
            }
        }

        &[class*='with-picto'] > span {
            display: inline-block;
        }

        .no-touchevents &:not([class*='with-picto']):hover,
        .no-touchevents &[class*='with-picto']:hover > span {
            color: $c-primary;
            text-decoration: none;
            border-color: $c-primary;
        }
    }

    &.back {
        font-size: 12px;
        line-height: 12px;
        color: $c-text;
        text-decoration: none;

        i.icon-arrow-left {
            margin-right: 5px;
            font-size: 15px;
        }

        .no-touchevents &:hover {
            color: $c-text;
            text-decoration: none;
        }
    }

    & > [class*='icon-'],
    & > [class*='fa-'] {
        //transition-property: transform;
        //transition: all $t-duration;
        transition: transform $t-duration;
    }

    &:focus {
        outline-width: 0;
    }
}

a {
    color: $link-text-color;
    font-weight: bold;
    text-decoration: underline;
    transition: {
        property: color, text-decoration;
        duration: $t-duration;
    }

    .no-touchevents &:hover {
        color: $link-text-color-hover;
        text-decoration: none;
    }
}

a.button,
span.button,
input.button,
button.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: $btn-min-h;
    padding: $btn-pad-h $btn-pad-w;

    background-color: $btn-bg-color;
    border: {
        width: $btn-border-width;
        style: solid;
        color: $btn-border-color;
        radius: $btn-border-radius;
    }

    font-family: $btn-text-font-f;
    font-weight: bold;
    font-size: $btn-text-font-s;
    line-height: $f-line-height-base;

    color: $btn-text-color;
    text-decoration: none;

    transition: {
        property: background-color, border-color, color, box-shadow;
        duration: $t-duration;
    }

    box-shadow: $btn-box-shadow;

    .no-touchevents &:hover,
    &.hover {
        background-color: $btn-bg-color-hover;
        border-color: $btn-border-color-hover;
        color: $btn-text-color-hover;
        text-decoration: none;
        box-shadow: $btn-box-shadow-hover;
    }

    &.button-secondary {
        background: $btn-bg-color-secondary;
        color: $btn-text-color-secondary;

        .no-touchevents &:hover {
            background-color: $btn-bg-color-secondary;
            color: $btn-text-color-secondary;
        }

        &.no-shadow {
            box-shadow: none;

            .no-touchevents &:hover {
                background-color: $btn-bg-color-hover;
                border-color: $btn-border-color-hover;
                color: $btn-text-color-hover;
            }
        }
    }

    &.button-neutral {
        background: $c-gray-light;
        color: $c-text;

        .no-touchevents &:hover,
        &.hover {
            background-color: $c-gray-light;
            color: $c-text;
        }
    }

    &.button-filter{
        box-shadow: none;
        border-radius: 100px;

        .no-touchevents &:hover{
            box-shadow: none;
        }
    }

    &.button-small{
        padding: math.round($btn-pad-h * 0.857) math.round($btn-pad-w * 0.667);
        font-size: math.round($btn-text-font-s * 0.875);
        border-radius: math.round($btn-border-radius * 0.833);
    }

    &.button-big {
        $btn-min-h: 40px !default;
        $btn-pad-h: ($btn-min-h - $btn-text-font-s) * 0.5 !default;

        padding: {
            top: (60px - $btn-text-font-s) * 0.5;
            bottom: (60px - $btn-text-font-s) * 0.5;
        }
        //font-size: math.round($btn-text-font-s * 0.875);
        //border-radius: math.round($btn-border-radius * 0.833);
    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        background: $btn-bg-color-disabled;
        color: $btn-text-color-disabled;
        box-shadow: none;
    }

    &.button-full-width-mobile{
        width: 100%;
        text-align: center;

        @media (min-width: ($s-mobile)) {
            width: auto;
            text-align: inherit;
        }
    }

    &.button-icon {
        padding: $btn-pad-h;
    }

    &.button-round {
        flex-direction: column;
        width: 50px;
        height: 50px;
        padding: 0;
        border-radius: 50px;

        & > [class*='icon-'],
        & > [class*='fa-'] {
            margin-right: 0;
            margin-left: 0;
            transition-property: transform;

            &:not([class*='icon-arrow']) {
                font-size: 20px;
            }
        }
    }

    &.button-scroll {
        cursor: pointer;

        &.scroll-up {
            position: fixed;
            z-index: 99;
            right: 15px;
            bottom: 15px;
            width: 60px;
            min-height: 60px;
            height: 60px;
            padding-bottom: 2px;
            visibility: hidden;
            opacity: 0;
            transition: all $t-duration linear;

            &.visible {
                font-size: 24px;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

a.arrow,
span.arrow,
button.arrow,
input.arrow {
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    .no-touchevents &:hover {
        text-decoration: none;
    }

    & > [class*='icon-arrow'] {
        margin-left: 10px;
        margin-right: 0;
        //transition-property: transform;
        //transition-duration: $t-duration;
    }

    &.button-round {
        & > [class*='icon-arrow'] {
            margin-left: 0;
        }
    }
}

a[class*='with-picto'],
span[class*='with-picto'],
button[class*='with-picto'],
input[class*='with-picto'] {
    display: inline-flex;
    align-items: center;

    &.with-picto-before {

        & > [class*='icon-']:first-of-type,
        & > [class*='fa-']:first-of-type {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    &.with-picto-after {

        & > [class*='icon-']:last-of-type,
        & > [class*='fa-']:last-of-type {
            margin-right: 0;
            margin-left: 10px;
        }
    }
}

a.arrow-left,
span.arrow-left,
button.arrow-left,
input.arrow-left {
    @extend .arrow;

    & > [class*='icon-arrow'] {
        margin-left: 0;
        margin-right: 10px;
    }

    .no-touchevents &:hover,
    .no-touchevents .block-link:hover & {
        & > [class*='icon-arrow'] {
            transform: translate(-5px, 0);
        }
    }
}

a.arrow-right,
span.arrow-right,
button.arrow-right,
input.arrow-right {
    @extend .arrow;

    .no-touchevents &:hover,
    .no-touchevents .block-link:hover & {
        & > [class*='icon-arrow'] {
            transform: translate(5px, 0);
        }
    }
}

a.arrow-up,
span.arrow-up,
button.arrow-up,
input.arrow-up {
    @extend .arrow;

    .no-touchevents &:hover,
    .no-touchevents .block-link:hover & {
        & > [class*='icon-arrow'] {
            transform: translate(0, -5px);
        }
    }
}

a.arrow-down,
span.arrow-down,
button.arrow-down,
input.arrow-down {
    @extend .arrow;

    .no-touchevents &:hover,
    .no-touchevents .block-link:hover & {
        & > [class*='icon-arrow'] {
            transform: translate(0, 5px);
        }
    }
}

button.slick-arrow {
    text-decoration: none;

    &,
    &.button,
    &.button-round {
        position: absolute;
        width: 20px;
        height: 20px;
        min-height: 20px;
        color: $btn-text-color;
        transition-property: background-color, border-color;

        &.slick-custom {
            width: $btn-slider-sm-size;
            height: $btn-slider-sm-size;
            min-height: $btn-slider-sm-size;

            &::before {
                content: '';
            }

            @media (min-width: $s-mobile-md) {
                width: $btn-slider-lg-size;
                height: $btn-slider-lg-size;
                min-height: $btn-slider-lg-size;
            }

            @media (min-width: $s-absolute-slider-btn) {
                position: absolute;
                //top: calc(50% - 22.5px);
                //transform: none;
            }

            &.slick-prev, &.slick-left {
                //left: -($btn-slider-sm-size / 2);
                left: -$btn-slider-sm-size * 0.5;

                @media (min-width: $s-mobile-md) {
                    //left: -($btn-slider-lg-size / 2);
                    left: -$btn-slider-lg-size * 0.5;
                }

                @media (min-width: $s-absolute-slider-btn + (($btn-slider-lg-size + 15px) * 2)) {
                    left: -($btn-slider-lg-size + 15px);
                }

                @media (min-width: $s-absolute-slider-btn + (($btn-slider-lg-size + 15px) * 4)) {
                    left: -(($btn-slider-lg-size + 15px) * 2);
                }
            }

            &.slick-next, &.slick-right {
                //right: -($btn-slider-sm-size / 2);
                right: -$btn-slider-sm-size * 0.5;

                @media (min-width: $s-mobile-md) {
                    //right: -($btn-slider-lg-size / 2);
                    right: -$btn-slider-lg-size * 0.5;
                }

                @media (min-width: $s-absolute-slider-btn + (($btn-slider-lg-size + 15px) * 2)) {
                    right: -($btn-slider-lg-size + 15px);
                }

                @media (min-width: $s-absolute-slider-btn + (($btn-slider-lg-size + 15px) * 4)) {
                    right: -(($btn-slider-lg-size + 15px) * 2);
                }
            }
        }

        &:not(.slick-custom) {

            &::before {
                font-family: $f-icons;
                font-size: 12px;
                line-height: 20px;
                color: $c-text;
                //transition: transform $t-duration;
                //transition: all $t-duration;
            }

            &.slick-prev, &.slick-left {
                &::before {
                    content: '\0042';
                }
            }

            &.slick-next, &.slick-right {
                &::before {
                    content: '\0041';
                }
            }
        }

        &:not(.slick-custom), &.position-static {
            transform: none;
        }

        &.position-static {
            position: static;

            @media (min-width: $s-absolute-slider-btn) {
                position: absolute;
            }
        }

        .no-touchevents &:not(.slick-disabled):hover, .no-touchevents &:not(.slick-disabled):focus, .no-touchevents &:not(.slick-disabled):focus-within, .no-touchevents .block-link:hover &:not(.slick-disabled) {
            background-color: $btn-bg-color-hover;
            color: $btn-text-color-hover;
            box-shadow: none;

            & > i {
                color: $btn-text-color-hover;
            }
        }

        &.slick-disabled {
            background: $btn-bg-color-disabled;
            color: $btn-text-color-disabled;
            //pointer-events: none;

            .no-touchevents &:hover, .no-touchevents &:focus, .no-touchevents &:focus-within, .no-touchevents .block-link:hover & {
                //background-color: $bg-white;
                background: $btn-bg-color-disabled;
                color: $btn-text-color-disabled;
                cursor: default;
            }
        }

        &.slick-prev, &.slick-next {
            &:not(.slick-disabled) {
                background-color: $btn-bg-color;

                .no-touchevents &:hover {
                    //background-color: $btn-bg-color-hover;
                }
            }
        }

        &.slick-prev, &.slick-left {
            left: -10px;

            &.position-static {
                margin-right: 10px;
            }

            @media (min-width: $s-absolute-slider-btn) {
                left: -35px; // 20px + 15px
            }

            @media (min-width: $s-absolute-slider-btn + 200px) {
                left: -70px; // (20px + 15px) * 2
            }
        }

        &.slick-next, &.slick-right {
            right: -10px;

            &.position-static {
                margin-left: 10px;
            }

            @media (min-width: $s-absolute-slider-btn) {
                right: -35px; // 20px + 15px
            }

            @media (min-width: $s-absolute-slider-btn + 200px) {
                right: -70px; // (20px + 15px) * 2
            }
        }

        & > [class*='icon-arrow'],
        & > i.fa {
            //transition-property: transform;

            &:not(.icon-arrow):not(.icon-arrow-right):not(.icon-arrow-left) {
                font-size: 15px;
                //line-height: 20px;
            }
        }
    }
}

a.button-video {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        //width: 50px;
        //height: 50px;
        color: $c-white;
        background-color: transparent;
        border-radius: 100%;

        //@media (min-width: $s-mobile) {
        //    width: 70px;
        //    height: 70px;
        //}

        //&::before, &::after {
        //    content: '';
        //    position: absolute;
        //    top: -5px;
        //    left: -5px;
        //    width: 60px;
        //    height: 30px;
        //    border: {
        //        style: solid;
        //        color: $c-white;
        //        width: 3px 3px 0 3px;
        //        radius: 30px 30px 0 0;
        //    }
        //    box-sizing: border-box;
        //    transform-origin: bottom;
        //    transition-property: transform;
        //    transition-duration: $t-duration*2;
        //    transition-timing-function: ease-out;
        //
        //    @media (min-width: $s-mobile) {
        //        width: 80px;
        //        height: 40px;
        //        border-radius: 40px 40px 0 0;
        //    }
        //}
        //
        //&::before {
        //    transform: rotate(90deg);
        //}
        //
        //&::after {
        //    transform: rotate(180deg);
        //}

        & > i {
            font-size: 28px;
            transition: color $t-duration;

            @media (min-width: $s-mobile) {
                font-size: 40px;
            }
        }
    }

    .no-touchevents &:hover {
        text-decoration: none;

        & > span {

            &::after {
                transform: rotate(270deg);
            }

            & > i {
                color: $c-second;
            }
        }
    }
}






.bg-dark,
section.bg-dark {

    a {
        color: $c-text-light;

        .no-touchevents &:hover {
            color: $c-text-light;
        }
    }

    a.button,
    span.button,
    input.button,
    button.button {
        background: $c-gray-light;
        color: $c-text;

        .no-touchevents &:hover,
        &.hover {
            background-color: $c-gray-light;
            color: $c-text;
        }
    }

    button.slick-arrow {

        &,
        &.button,
        &.button-round {
            border-color: transparent;

            .no-touchevents &:hover,
            .no-touchevents &:focus,
            .no-touchevents &:focus-within {

                &.button-round:not(.slick-disabled) {
                    border-color: $c-primary;
                }

                &.button:not(.button-round) {
                    border-color: transparent;
                }
            }
        }
    }
}