.map-container, .map {
    position: relative;
}

.map {
    height: 100%;

    &:not(.rendered) {
        .marker {
            display: none;
        }
    }
}

.gmnoprint .gm-style-mtc,
.gm-svpc,
.gm-style-cc {
    display: none;
}

.gm-style {
    .gm-style-iw-c {
        min-width: 200px !important;
        max-width: 250px !important;
        padding: 0;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: $c-text;
        border-radius: 15px;
        background-color: $bg-white;
        box-shadow: 0 0 10px 1px transparentize($c-primary, 0.8);

        .picture {
            display: none;

            @media (min-width: $s-tablet) {
                display: block;
                min-height: 100px;
                //margin-bottom: 10px;
                background: {
                    size: cover;
                    repeat: no-repeat;
                    position: center;
                }
            }
        }

        .details {
            padding: 10px;
            font: {
                family: $f-text;
                size: $f-size-base - 2px;
            }

            @media (min-width: $s-mobile) {
                padding: 20px;
                font-size: $f-size-base;
            }

            p.title {
                margin-bottom: 10px;
                padding-right: 15px;
                font: {
                    family: $f-title;
                    size: 1.2em;
                }
                line-height: $f-line-height-base;
            }

            & > :last-child {
                margin-bottom: 0;
            }
        }

        button {
            top: 10px !important;
            right: 10px !important;
            width: 15px !important;
            height: 15px !important;
            font-size: 15px;
            color: $c-text;
            opacity: 1;
            transition: {
                property: transform, color;
                duration: $t-duration;
            }

            @media (min-width: $s-mobile) {
                top: 15px !important;
                right: 15px !important;
            }

            .no-touchevents &:hover {
                color: $c-primary;
            }

            & > span {
                display: inline-block !important;
                width: auto !important;
                height: auto !important;
                margin: 0 !important;
                font-family: pure-icons;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                -webkit-mask-image: none !important;
                background: none;

                &::before {
                    content: map-get($pure-icons-map, "cross");
                }
            }
        }
    }

    .gm-style-iw-d {
        //padding-right: 20px !important;
        padding-right: 0 !important;
        overflow: auto !important;
    }

    .gm-style-iw-t::after {
        background: $bg-white;
        top: -5px;
    }

    .cluster {

        & > div {
            line-height: 49px !important;

            & > span {
                font: {
                    family: $f-primary !important;
                    size: 15px !important;
                    weight: 600 !important;
                }
                color: $c-light !important;
            }
        }

        & > img {
            max-width: 100%;
        }
    }
}