
$site-width: 1240px;
$site-width-pad-limited: 100px;
$site-width-limited: $site-width - 2 * $site-width-pad-limited;
$site-width-pad-x: 30px;
$section-pad-y-min: 25px;
$section-pad-y-max: 40px;

$logo-width-min: 66px;
$logo-width-max: 66px;

$c-primary: #9AD2C9;
$c-primary-lighter: #5FBEB3;
$c-second: #D5ECE8;
$c-second-lighter: #F1F9F7;
$c-third: #D1957C;
$c-fourth: #FBF1ED;

$c-border: #ebebeb;

$c-light: #fafafa;
$c-dark: $c-text;
$bg-dark: $c-primary;

$link-text-color: $c-text;
$link-text-color-hover: $c-primary;
$btn-text-color: $c-white;
$btn-text-color-hover: $c-white;
$btn-text-color-secondary: $c-primary-lighter;
$btn-text-color-secondary-hover: $c-primary-lighter;
$btn-bg-color: $c-primary;
$btn-bg-color-hover: $c-primary;
$btn-bg-color-secondary: $c-second-lighter;
$btn-bg-color-secondary-hover: $c-second-lighter;

$form-field-border-width: 2px;
$form-field-border-color: transparent;
$form-field-border-color-hover: $c-primary;
$form-field-pad-h: 10px;
$form-field-pad-w: 20px;