.ui-widget.ui-widget-content {
    border: 0;
    //overflow: auto;
    max-height: 100%;
    //max-height: 90%;
    font-family: $f-text;
    color: $c-text;
}

.ui-autocomplete {
    z-index: 20000;
}

.ui-dialog {
    //position: fixed;
    padding: 0;

    &.ui-corner-all {
        border-radius: 0;
    }

    .ui-dialog-buttonpane {
        margin: 0;
        padding: 0;
        border: none;
    }

    .ui-button.dialog-close {
        position: absolute;
        z-index: 21;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 10px;
        right: 10px;
        width: 40px;
        min-height: 40px;
        height: 40px;
        margin: 0;
        padding: 10px;
        background-color: transparent;
        border: 2px solid $c-white;

        @media (min-width: $s-mobile-md) {
            top: -20px;
            right: -20px;
        }

        &::before {
            content: "\0046"; // TODO: check icon-cross code
            display: inline-block;
            font-family: $f-icons;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transition: transform $t-duration;
        }

        &:hover,
        &:focus {
            margin: 0;
            background-color: $c-primary;
            border-color: $c-primary;
            color: $c-light;
        }
        &:hover {

            &::before {
                transform: rotate(90deg);
            }
        }
    }

    .ui-dialog-content {
        //overflow: visible;
        //padding: 15px 20px;
        padding: 0;
        overflow-y:auto;
        background-color: $bg-white;
        color: $c-text;

        //@media (min-width: $s-mobile) {
        //    padding: 30px 40px;
        //}
        //
        @media (min-width: $s-tablet) {
            max-width: calc(100vw - 100px);
            //padding: 40px 50px;
        }

        .top {
            margin-bottom: 20px;
            padding: 40px 0 0;

            @media (min-width: $s-mobile) {
                margin-bottom: 40px;
                padding: 0 50px;
            }

            @media (min-width: $s-tablet) {
                padding: 0;
            }

            h2, .h2 {
                margin-bottom: 0;
            }
        }

        & > div {
            padding: 15px 20px;

            @media (min-width: $s-mobile) {
                padding: 30px 40px;
            }

            @media (min-width: $s-tablet) {
                padding: 40px 50px;
            }
        }
    }

    .dialog-content {
        //max-height: 700px;
        //overflow-y: scroll;
        text-align: center;
    }

    .ui-dialog-titlebar {
        display: none;
    }

    .ui-widget-content {
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        //color: inherit;
    }
    .ui-widget {
        //font-family: $f-primary;
    }
    .ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
        //font-family: $f-primary;
        font-size: 14px;
    }

    .h1, .h2 {
        padding: 10px 20px;
        text-align: center;
    }
}

.ui-widget-overlay {
    background-color: #000000;
    opacity: 0.4;
    z-index: 10002;
    transition: display 4*$t-duration;

    //@media (min-width: $s-mobile) {
    //    opacity: 0.7;
    //}
}

.dialog-popup {

    .popup-content {
        position:relative;
        padding-top:30px;
        background:#ffffff;

        @media(min-width: $s-tablet) {
            width: auto;
            padding: 50px 0 30px;
        }

        .popup-close-button {
            position: absolute;
            top: 30px;
            right: 30px;
            padding: 2px 5px;
            font-size: 15px;
            line-height: 25px;
            color: $c-dark;
            cursor: pointer;
            background: none;
            border: none;

            .no-touchevents &:hover, .no-touchevents &:focus, .no-touchevents &:focus-within {
                color: $c-primary;
            }
        }
    }
}