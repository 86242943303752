$form-field-pad-h: 15px !default;
$form-field-bg-color: #fff !default;
$form-field-border-color: #ccc !default;
$form-field-text-color: #000 !default;
$form-field-placeholder-color: #ccc !default;
$form-field-label-font-f: 'Arial, sans-serif' !default;
$form-field-text-font-f: 'Arial, sans-serif' !default;

select.select2 {
    color: $form-field-placeholder-color;
}

.select2-container {
    max-width: 100%;
}
.select2-container--default {

    .select2-selection {
        width: 100%;
        //height: ($form-field-pad-h * 4) + ($form-field-border-width * 2);
        height: inherit;
        min-height: inherit;
        padding: $form-field-pad-h $form-field-pad-w;
        background: none;
        background-color: $form-field-bg-color;
        -webkit-appearance: none;
        border: {
            style: solid;
            width: $form-field-border-width;
            color: $form-field-border-color;
            radius: $form-field-border-radius;
        }
        box-shadow: none;
        font: {
            family: $form-field-label-font-f;
            size: $form-field-label-font-s;
        }
        line-height: $form-field-pad-h * 2;
        color: $form-field-text-color;
        transition: border-color $t-duration;

        box-shadow:
                inset 3.3px 3.3px 2.2px 0 rgba(174, 174, 192, 0.2), // Top left inner shadow
                inset -2.2px -2.2px 2.2px 0 rgba(255, 255, 255, 0.7), // Bottom right inner highlight
                -1px -1px 3px 0 #fff, // Top left outer highlight
                1.5px 1.5px 3px 0 rgba(174, 174, 192, 0.2); // Bottom right outer highlight

        cursor: default;

        .no-touchevents &:hover {
            //border-color: $form-field-border-color-hover;
        }

        .select2-selection__rendered {
            display: block;
            //max-height: $form-field-label-font-s;
            height: $form-field-pad-h * 2;
            padding-left: 0;
            padding-right: 0;
            font: {
                family: $form-field-text-font-f;
                size: $form-field-text-font-s;
            }
            color: $form-field-placeholder-color;
            line-height: $form-field-pad-h * 2;

            .select2-search--inline {
                //line-height: 25px;
            }
        }

        .select2-search__field {
            max-height: inherit;
            margin-top: 0;
            font-size: $form-field-text-font-s;
            //line-height: $form-field-label-font-s;
            line-height: 20px;
            color: $form-field-text-color;

            &::placeholder {
                color: $form-field-placeholder-color;
            }
        }

        .select2-selection__placeholder {
            color: $form-field-placeholder-color;
        }

        .select2-selection__arrow {
            top: auto;
            bottom: calc(50% - 10px);
            right: 15px;
            width: 20px;
            height: 20px;
            font-size: 20px;
            text-align: center;
            color: $form-field-text-color;
            //transition: top $t-duration;
            transition: all 0.15s ease-in-out;
            line-height: 1;

            b {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0;
                display: inline-block;
                border: none;
                font-family: $f-icons;
                font-size: 20px;
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                transition: color $t-duration, transform $t-duration;

                // Arrow
                &:after {
                    display: inline-block;
                    content:'\0043';
                    transition: color $t-duration, transform $t-duration;
                }
            }
        }
    }

    &.not-empty {

        .select2-selection .select2-selection__rendered {
            color: $form-field-text-color;
        }

        .select2-selection--multiple .select2-selection__rendered {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            height: inherit;
        }
    }

    &:not(.not-empty):focus-within {

        .select2-selection .select2-selection__rendered {
            //color: transparent;
        }
    }

    .no-touchevents &:not(.select2-container--open):hover,
    &.select2-container--focus {

        .select2-selection {

            .select2-selection__arrow {

                b::after {
                    color: $c-primary;
                }
            }
        }

        .select2-selection--multiple {
            border-width: $form-field-border-width;
            border-radius: $form-field-border-radius;
            border-color: $form-field-border-color-hover;
        }
    }

    &.select2-container--open,
    &.select2-container--open.select2-container--above,
    &.select2-container--open.select2-container--below {
        .select2-selection,
        .select2-selection--single,
        .select2-selection--multiple {
            border-width: $form-field-border-width;
            border-color: $form-field-border-color-hover;
            border-radius: $form-field-border-radius;

            .select2-selection__arrow {
                b::after {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .select2-selection--multiple {
        .select2-selection__choice {
            float: none;
            margin: 0;
            background-color: $c-second;
            border: {
                width: 0;
                color: transparent;
            };

            mark {
                font: {
                    family: $form-field-text-font-f;
                    size: $form-field-text-font-s;
                }
                background-color: transparent;
            }
        }
    }

    .select2-dropdown {
        z-index: 10003; // popup + 1
        top: calc(100% + 10px);
        //top: calc(100%);
        margin-top: 0;
        padding: 0;
        //transform: translateY(-5px);

        background-color: $bg-white;
        border: {
            width: 0;
            radius: $form-field-border-radius;
        }

        box-shadow:
                1.5px 1.5px 5px 0 transparentize(#AEAEC0, 0.6),
                -1px -1px 3px 0 $c-white,
                inset 0 0 0 0.2px transparentize($c-white, 0.8)
        ;

        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        transform: translateY(10px);
        pointer-events: none;
        opacity: 0;

        font-family: $form-field-text-font-f;
        font-size: $f-size-base;

        transition: opacity $t-duration, transform 2*$t-duration;
        transition-delay: 3s;
    }

    &.select2-container--open {
        .select2-dropdown {
            transform: translateY(0);
            opacity: 1;
            pointer-events: auto;
        }
        .select2-dropdown--above {
            //border-bottom: 1px solid $form-field-border-color;
        }
        .select2-dropdown--below {
            //border: 1px solid $c-text;
        }
    }

    &.select2-hide-search {

        .select2-search--dropdown {
            display: none;
        }
    }

    .select2-search--dropdown {
        .select2-search__field {
            border-color: $form-field-border-color;
        }
    }

    .select2-results > .select2-results__options {
        //max-height: inherit;
    }

    .select2-results__option {
        //min-height: 40px;
        //padding: 0 26px 0 15px;
        padding: 10px 15px;
        font-weight: 400;
        //line-height: 40px;
        line-height: 1;
        list-style: none;
        color: $c-text;
        text-align: left;
        transition: all 0.2s;

        mark {
            padding: 0;
            font: {
                family: $form-field-text-font-f;
                weight: bold;
                size: $f-size-base;
            }
            background: none;
            transition: all 0.2s;
        }
    }

    .select2-results__option--highlighted,
    .select2-results__option--highlighted[aria-selected] {
        border: none;
        background-color: transparent;
        color: $c-text;

        mark {
            color: $c-text;
        }
    }

    .select2-results__option:hover {
        border: none;
        background-color: transparent;
        color: $c-primary;

        mark {
            color: $c-primary;
        }
    }

    .select2-results__option[aria-selected=true] {
        background-color: $bg-dark;
        color: $c-text-light;

        mark {
            color: $c-text-light;
        }
    }

    .select2-results__message {
        //display: none;
    }
}






.bg-dark,
section.bg-dark {

    .select2-container--default {

        .select2-selection {
            background-color: $form-field-bg-color-bis;
            color: $form-field-text-color-bis;
            border-color: $form-field-border-color-bis;

            .no-touchevents &:hover {
                border-color: $form-field-border-color-bis-hover;
            }

            .select2-selection__rendered {
                color: $form-field-placeholder-color-bis;
            }

            .select2-search__field {
                color: $form-field-text-color-bis;

                &::placeholder {
                    color: $form-field-placeholder-color-bis;
                }
            }

            .select2-selection__placeholder {
                color: $form-field-placeholder-color-bis;
            }

            .select2-selection__arrow {
                color: $form-field-text-color-bis;
            }
        }

        &.not-empty {

            .select2-selection .select2-selection__rendered {
                color: $form-field-text-color-bis;
            }
        }

        //.select2-results__option[aria-selected=true] {
        //    color: $form-field-text-color-bis;
        //}

        .select2-dropdown {
            background-color: $form-field-bg-color-bis;
        }

        .select2-search--dropdown {
            .select2-search__field {
                border-color: $form-field-border-color-bis;
            }
        }
    }
}