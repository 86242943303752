.blogs-list,
.blogs-featured{
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px 20px;

    @media (min-width: $s-mobile) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $s-tablet) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: $site-width) {
        gap: 50px;
    }

    &:not(.other-blogs-list) {

        .blog-item {
            //@media (min-width: $s-tablet) {
            //    width: calc((100% - 60px) / 3);
            //
            //    &:nth-child(3) {
            //        margin-top: 0;
            //    }
            //
            //    &:nth-child(3n+1) {
            //        margin: {
            //            right: 15px;
            //            left: 0;
            //        }
            //    }
            //
            //    &:nth-child(3n+2) {
            //        margin: {
            //            right: 15px;
            //            left: 15px;
            //        }
            //    }
            //
            //    &:nth-child(3n) {
            //        margin: {
            //            left: 15px;
            //            right: 0;
            //        }
            //    }
            //}
        }
    }

    &.other-blogs-list {

        .blog-item {

            .picture {
                height: 150px;
            }

            .summary {
                display: none;
            }
        }
    }

    .blog-item {
        position: relative;
        //margin: 20px 0 0;
        padding: 0;
        text-align: left;
        box-shadow: $shadowLarge;

        transition: {
            property: transform, box-shadow;
            duration: $t-duration;
        }
        border-radius: 15px;
        overflow: hidden;
        color: $c-text;

        @media (min-width: $s-mobile) {
            display: flex;
            flex-direction: column;
            //width: calc(50% - 15px);
            //margin-top: 30px;
            width: 100%;

            //&:nth-child(2) {
            //    margin-top: 0;
            //}
            //
            //&:nth-child(2n+1) {
            //    margin: {
            //        right: 15px;
            //        left: 0;
            //    }
            //}
            //
            //&:nth-child(2n) {
            //    margin: {
            //        left: 15px;
            //        right: 0;
            //    }
            //}
        }
        //
        //&:first-child {
        //    margin-top: 0;
        //}

        //.categories {
        //    display: flex;
        //    position: absolute;
        //    top: 10px;
        //    right: 10px;
        //    margin: 0;
        //    padding: 0;
        //    list-style: none;
        //    font: {
        //        size: 14px;
        //        weight: 600;
        //    }
        //    line-height: 20px;
        //    color: $c-light;
        //
        //    @media (min-width: $s-mobile) {
        //        top: 15px;
        //        right: 15px;
        //    }
        //
        //    & > li {
        //        padding: 5px 15px;
        //        border-radius: 15px;
        //        background-color: $c-second;
        //
        //        &:not(:last-child) {
        //            margin-right: 10px;
        //        }
        //    }
        //}

        .picture {
            height: 50vw;
            overflow: hidden;

            @media (min-width: $s-mobile) {
                height: 155px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: scale(1);
                transition: transform $t-duration * 2;
            }
        }

        .infos {
            display: flex;
            flex-direction: column;
            padding: 20px 30px;
            background-color: $bg-light;

            @media (min-width: $s-mobile) {
                flex-grow: 1;
            }

            .blog-title {
                @include multilineEllipsis(3);
                margin-bottom: 4px;
            }

            .name {
                color: $c-text;
                text-decoration: none;

                .no-touchevents &:hover,
                .no-touchevents &:focus,
                .no-touchevents &:focus-within {
                    text-decoration: none;
                    color: $c-text;
                }
            }

            .summary{
                @include multilineEllipsis(3);
                font-size: 12px;
                margin-bottom: 20px;
            }

            i.icon-arrow-right {
                font-size: 20px;
                line-height: 20px;
                color: $c-second;
            }

            .button{
                margin-top: auto;
                align-self: center;
            }
        }

        .no-touchevents &:hover,
        .no-touchevents &:focus,
        .no-touchevents &:focus-within {
            box-shadow: $shadowShort;

            .picture{
                img{
                    transform: scale(1.02);
                }
            }

            .name{
                color: inherit;
            }

            .button{
                @extend .hover;
            }
        }
    }
}

body.blog {

    &.index {

        section.blog-categories {
            $toggleBreakpoint: $s-mobile;

            padding-bottom: 0px;

            &:not(.bg-dark) {
                background: none;
            }

            .wrapper{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-wrap: wrap;

                @media (min-width: $toggleBreakpoint) {
                    flex-wrap: nowrap;
                }
            }


            .blog-counter{
                margin-right: auto;
                font-size: 20px;
                font-weight: bold;
                flex: 1 0 auto;

                @media (min-width: $toggleBreakpoint) {
                    margin-right: 20px;
                }
            }

            .blog-categories-toggle{
                @media (min-width: $toggleBreakpoint) {
                    display: none;
                }
            }

            .blog-categories-menu-container{
                display: none;
                flex: 1 1 100%;

                @media (min-width: $toggleBreakpoint) {
                    display: flex !important;
                    flex: 0 1 auto;
                }
            }
            ul.blog-categories-menu {
                $gap: 5px;

                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin: 0 -#{$gap};
                margin-top: 20px;
                padding: 0;
                list-style: none;


                @media (min-width: $toggleBreakpoint) {
                    margin-top: 0;
                }

                & > li {
                    margin: $gap $gap;
                    min-width: max-content;

                    &.current {
                        & > a {
                            color: white;
                            border-color: $c-primary;
                            background-color: $c-primary;
                        }
                    }

                    &:not(.current) {

                        & > a {
                            .no-touchevents &:hover,
                            .no-touchevents &:focus,
                            .no-touchevents &:focus-within {
                                border-color: $c-primary;
                                background-color: $c-second-lighter;
                            }
                        }
                    }

                    & > a {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        line-height: 1;
                        padding: 10px 15px;
                        font-size: 12px;
                        color: $c-primary;
                        text-decoration: none;
                        border: 1px solid $c-primary;
                        background-color: $c-light;
                        transition: {
                            property: background-color, color, box-shadow, border-color;
                            duration: $t-duration;
                        }

                        @media (min-width: $s-mobile) {
                            padding: 11px 17px;
                            font-size: 14px;
                        }

                        .no-touchevents &:hover,
                        .no-touchevents &:focus,
                        .no-touchevents &:focus-within {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        section.blogs{

            .blogs-featured{
                margin-bottom: 30px;

                @media (min-width: $site-width) {
                    margin-bottom: 50px;
                }

                .block-intro{
                    display: none;
                    align-items: center;
                    padding: 40px 40px;

                    .text{
                        margin-bottom: 0;
                    }

                    @media (min-width: ($s-mobile)) {
                        display: flex;
                    }
                }

                .blog-item{
                    @media (min-width: $s-tablet) {
                        grid-column: span 2; // Make the item takes 2 columns
                        flex-direction: row;

                        .picture{
                            height: auto;
                            width: 240px;
                            flex: 1 0 auto;
                        }

                        .infos{
                            justify-content: center;
                            padding: 30px;
                        }

                        .name{
                            font-size: 20px;
                        }

                        .summary{
                            font-size: 16px;
                        }

                        .button{
                            align-self: flex-start;
                            margin-top: 0;
                        }
                    }
                }

            }
        }

        section.content-blocks{
            margin-bottom: 40px;
        }
    }

    &.detail {

        .columns{
            $columnsBreakpoint: $s-tablet;

            display: flex;
            flex-direction: column;

            @media (min-width: ($columnsBreakpoint)) {
                flex-direction: row;
            }

            .column-left{

                @media (min-width: ($columnsBreakpoint)) {
                    flex: 0 0 $site-width * 0.6;
                }
            }

            .column-right{

                @media (min-width: ($columnsBreakpoint)) {
                    flex: 0 0 193px;
                    margin-left: auto;
                }

                .sticky{
                    position: sticky;
                    top: 60px;
                    padding-top: 60px;
                }

                .sharer{

                    .content{
                        padding: 50px 36px 0px 36px;

                        .wrapper{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            @media (min-width: ($columnsBreakpoint)) {
                                min-height: 70vh;
                                max-height: 700px;
                            }
                        }

                        .title{
                            font-weight: bold;
                            margin-bottom: 6px;
                        }

                        .text{
                            font-size: 12px;
                            margin-bottom: 30px;
                        }

                        .share-links{

                            ul.share-links-list{
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                gap: 10px;

                                > li{
                                    width: 150px;

                                    @media (min-width: ($columnsBreakpoint)) {
                                        width: 100%;
                                    }

                                    > a{
                                        width: 100%;

                                        &.button{

                                            > i {
                                                margin-right: 6px;
                                            }

                                            @media (min-width: ($columnsBreakpoint)) {
                                                font-size: 12px;
                                                padding: 10px;
                                                min-height: auto;
                                            }
                                        }

                                        &.facebook{
                                            background-color: #3c5a99;
                                        }
                                        &.linkedin{
                                            background-color: #2967b4;
                                        }
                                        &.twitter{
                                            background-color: #1da0f2;
                                        }
                                        &.whatsapp{
                                            background-color: #25D366;
                                        }
                                    }
                                }
                            }
                        }

                        .picture{
                            padding-top: 40px;
                            height: 164px;

                            @media (min-width: ($columnsBreakpoint)) {
                                margin-top: auto;
                                padding-top: 20px;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }

        section.banner {
            .banner-content {
                padding-top: 26px;
                padding-bottom: 10px;
                flex-direction: column;

                .picture {
                    max-height: 290px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    margin: 0;
                    margin-bottom: 20px;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        max-height: none;
                    }
                }

                .tags{
                    margin-bottom: 10px;
                }
            }
        }

        section.blog-detail {

            //.back-and-share {
            //    display: flex;
            //    flex-direction: column;
            //    align-items: center;
            //    padding-bottom: 15px;
            //
            //    @media (min-width: $s-mobile-md) {
            //        flex-direction: row;
            //        justify-content: space-between;
            //    }
            //
            //    .share-links {
            //        margin-top: 10px;
            //        text-align: right;
            //
            //        @media (min-width: $s-mobile-md) {
            //            margin-top: 0;
            //        }
            //    }
            //}


        }

        section.table-of-contents {

            @media (min-width: ($s-mobile)) {
                margin-bottom: 8px;
            }

            .content{
                padding: 40px 40px;

                @media (min-width: ($s-mobile)) {
                    padding: 30px 50px;
                }
            }

            .title{
                margin-bottom: 8px;
            }

            ul {
                margin: 0;
                padding: 0 0 0 20px;
                list-style-type: none;

                li {
                    display: flex;
                    margin-left: 0;
                    font-size: 14px;

                    @media (min-width: ($s-mobile)) {
                        font-size: 16px;
                    }

                    $offset: 20px;
                    &.level-h2 {
                        margin-left: $offset * 0;
                    }
                    &.level-h3 {
                        margin-left: $offset * 1;
                    }
                    &.level-h4 {
                        margin-left: $offset * 2;
                    }
                    &.level-h5 {
                        margin-left: $offset * 3;
                    }
                    &.level-h6 {
                        margin-left: $offset * 4;
                    }

                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }

                    &:before {
                        $size: 7px;
                        content:'';
                        background: url('../images/bullet.svg') no-repeat;
                        background-position: left $size;
                        padding-left: $size + 10px;
                        background-size: $size;
                    }

                    a{
                        text-decoration: none;
                    }
                }
            }
        }

        section.source{
            margin-top: 26px;
            padding: 10px 22px;
            font-size: 14px;

            @media (min-width: ($s-mobile)) {
                font-size: 16px;
                padding: 10px 30px;
            }

            .content{
                p{
                    display: inline;
                }
            }
            .label{
                margin-right: 6px;
            }

            .text{
                a{
                    font-weight: normal;
                }
            }
        }

        section.other-posts{
            padding-top: 50px;
            padding-bottom: 50px;

            .section-title{
                margin-bottom: 30px;

                @media (min-width: ($s-mobile)) {
                    margin-bottom: 48px;
                }
            }

            .link{
                text-align: center;
                margin-top: 30px;

                @media (min-width: ($s-mobile)) {
                    margin-top: 50px;
                }
            }
        }
    }
}