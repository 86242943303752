$rgpd-mobile: 768px !default;
$rgpd-tablet: 1024px !default;
$rgpd-retina: 2000px !default;
$rgpd-bg: $bg-site !default;
$rgpd-black: $c-dark !default;
$rgpd-white: $c-white !default;
$rgpd-primary: $c-primary !default;
$rgpd-second: $c-second !default;
$rgpd-text: $c-text !default;
$rgpd-f-primary: $f-primary !default;
$rgpd-f-text: $f-text !default;

.rgpd-advert {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparentize($rgpd-bg, 0.2);

    .map & {
        background-color: transparentize($rgpd-bg, 0.4);

        p {
            max-width: 75%;
            color: $rgpd-text;
        }

        a.button {
            color: $rgpd-text;
        }
    }

    &.hidden {
        display: none;
    }

    p {
        max-width: 50%;
        margin-bottom: 20px;
        font-size: 1.5em;
        color: $rgpd-text;
    }

    a.button {
        position: relative;
        top: unset;
        left: unset;
        cursor: pointer;
        //color: $rgpd-black;
    }
}

#cookies-popup {
    display: none;
}

#cookies-box {
    display: none;
    position: fixed;
    z-index: 400;
    bottom: 10px;
    left: 10px;
    width: calc(100vw - 20px);
    background-color: $rgpd-bg;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.1);

    .no-touchevents & {
        width: calc(100% - 20px);

    @media screen and (min-width: $rgpd-mobile) {
            width: 100%;
        }
    }

    @media screen and (min-width: $rgpd-mobile) {
        width: 100%;
        bottom: 0;
        left: 0;
    }

    h2, .h2 {
        color: $rgpd-text;
        margin: 0;
    }

    #cookies-box-close {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 0;
        color: $rgpd-black;
        background: none;
        border: none;
        cursor: pointer;
        transition: {
            property: transform;
            duration: $t-duration;
        }

        & > i {
            transition: all $t-duration;
        }

        .no-touchevents &:hover {
            & > i {
                transform: scale(0.7);
                color: $rgpd-primary;
            }
        }
    }

    .description {
        color: $rgpd-text;
        line-height: 20px;
        margin: 10px 0;
        font-size: 0.8em;

        @media screen and (min-width: $rgpd-mobile) {
            max-height: initial;
            overflow: hidden;
        }

        @media screen and (min-width: $rgpd-retina) {
            font-size: 1em;
            max-width: initial;
        }
    }

    .links {
        margin-bottom: 0;
        font-size: 0.8em;
        line-height: 20px;

        a {
            color: $rgpd-text;
            text-decoration: underline;

            .no-touchevents &:hover {
                text-decoration: none;
            }
        }
    }

    .cookies-box-content {
        max-width: 1240px;
        margin: 0 auto;
        padding: 15px;
        //background: {
        //    size: auto 32px;
        //    repeat: no-repeat;
        //    position: center 15px;
        //}

        @media screen and (min-width: $rgpd-mobile) {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            //background-position: 15px 15px;
        }
    }

    .logo{
        height: 30px;
        margin-bottom: 6px;
    }

    .left, .right {

        @media screen and (min-width: $rgpd-mobile) {
            flex: 1;
        }
    }

    .left {
        margin: 0;
        //padding-top: 75px;
        text-align: center;

        @media screen and (min-width: $rgpd-mobile) {
            padding-right: 20px;
            text-align: left;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: max-content;
        margin: 20px auto 0;
        text-align: center;

        @media screen and (min-width: $rgpd-mobile) {
            margin: 0;
            max-width: 160px;
        }

        .button {
            margin: 5px 0;
            //background-color: $rgpd-white;
            //color: $rgpd-primary;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
            //
            //.no-touchevents &:hover {
            //    background-color: $rgpd-primary;
            //    color: $rgpd-white;
            //}
            //
            //&.active,
            //.no-touchevents &.active:hover {
            //    background-color: $rgpd-primary;
            //    color: $rgpd-white;
            //}

            //& > i {
            //    font-size: 10px;
            //}
        }
    }
}

#cookies-popup {
    padding: 50px 10px;
    color: $rgpd-text;
    background-color: $rgpd-bg;

    #dialog-close {
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
        color: $rgpd-black;
        transition: all $t-duration;
        padding: 18px 24px;

        & > i {
            font-size: 20px;
            transition: all $t-duration;
        }

        .no-touchevents &:hover {
            & > i {
                transform: scale(0.7);
                color: $rgpd-primary;
            }
        }
    }

    .dialog-content {
        padding: 0 10px;

        @media screen and (min-width: $rgpd-mobile) {
            padding: 0 20px;
        }

        a {
            color: $rgpd-text;
            text-decoration: underline;

            .no-touchevents &:hover {
                text-decoration: none;
            }
        }
    }

    .head {
        max-width: 600px;
        margin: 0 auto 50px;
        text-align: center;

        .h2 {
            margin-bottom: 20px;
            padding: 0;
        }
    }

    .items {

        .item-type {
            margin-bottom: 20px;
            padding: 20px;
            text-align: left;
            border: {
                style: solid;
                width: 1px;
                color: #EFEFEF;
                radius: 5px;
            }

            .type {
                margin-bottom: 10px;
            }

            .text {
                margin-bottom: 20px;
            }

            .item {
                margin-bottom: 20px;

                @media screen and (min-width: $rgpd-mobile) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .label {
                    margin-bottom: 5px;
                    font-weight: bold;

                    @media screen and (min-width: $rgpd-mobile) {
                        margin-bottom: 0;
                    }
                }

                .buttons {
                    display: flex;

                    .button {
                        min-height: 30px;
                        padding: 5px 20px;
                        background-color: $rgpd-white;
                        color: $rgpd-black;

                        &:first-child {
                            margin-right: 10px;
                        }

                        .no-touchevents &:not(.active):hover {
                            color: $rgpd-black;
                            border-color: $rgpd-black;
                        }

                        &.active,
                        .no-touchevents &.active:hover {
                            background-color: $rgpd-primary;
                            color: $rgpd-white;
                        }

                        & > i {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}

.switch {

    input[type=checkbox] {
        position: absolute;
        height: 0;
        width: 0;
        visibility: hidden;

        &:checked {
            + label {
                background: $rgpd-primary;

                &:after {
                    left: calc(100% - 25px);
                    background: $rgpd-white;
                }
            }
        }
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 60px;
        height: 30px;
        background: transparentize($rgpd-primary, 0.5);
        display: block;
        border-radius: 100px;
        position: relative;
        transition: background $t-duration ease;

        &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 20px;
            height: 20px;
            background: $rgpd-white;
            border-radius: 90px;
            transition: all $t-duration;
        }

        //&:active {
        //    &:after {
        //        width: 28px;
        //    }
        //}
    }
}
