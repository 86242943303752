.pagination-container {
    $gap: 10px;
    $size: 41px;
    $breakpoint: $s-mobile;

    padding-top: 40px;

    nav ul.pagination {
        margin: 0;
        padding: 20px 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        li:not(.current-infos) {
            //display: block;
            //width: 35px;
            //height: 35px;
            //border-radius: 100%;
            margin-bottom: $gap;

            &.prev,
            &.next {
                //width: 40px;
                //height: 40px;

                &.disabled {
                    //display: none;

                    > a,
                    > span {
                        //color: $c-gray;
                    }
                }

                .text{
                    display: none;

                    @media (min-width: ($breakpoint)) {
                        display: inline-block;
                    }
                }

                //a,
                //span {
                //    width: 40px;
                //    min-height: 40px;
                //    height: 40px;
                //    font-size: 15px;
                //    transition-property: background-color, border-color, color;
                //
                //    & > [class*='icon-'],
                //    & > [class*='fa-'] {
                //        margin: 0;
                //        font-size: 15px;
                //    }
                //}
            }

            &.prev {
                margin-right: $gap;

                .text{
                    margin-left: 5px;
                }
            }

            &.next {
                margin-left: $gap;

                .text{
                    margin-right: 5px;
                }
            }

            &:not(.prev):not(.next){
                display: none;
                margin-right: $gap;
                width: $size;

                &:nth-last-of-type(2){
                    margin-right: 0;
                }

                @media (min-width: ($breakpoint)) {
                    display: block;
                }
            }

            &.active {
                > a,
                > span {
                    background-color: $c-gray-light;
                    color: $c-text;
                    cursor: default;

                    .no-touchevents &:hover,
                    &.hover {
                        background-color: $c-gray-light;
                        color: $c-text;
                    }
                }
            }

            &.disabled {

                > a,
                > span {
                }
            }

            > a,
            > span {
                display: flex;
                //flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: bold;
                //width: 100%;
                //height: 100%;
                //min-height: inherit;
                //font-family: $f-primary;
                //color: $c-text;
                //text-decoration: none;
                height: $size;
            }

            > a {
                .no-touchevents &:hover {
                }
            }
        }

        li.current-infos{
            margin-bottom: $gap;
            padding: 0 12px;

            @media (min-width: ($breakpoint)) {
                display: none;
            }

            > a,
            > span {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: bold;
                height: $size;
            }
        }

    }
}